import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MainService } from './services/main.service';
import { UtilService } from './services/util.service';
import { Title } from '@angular/platform-browser';
import { APP_MODEL_DATA } from './model-data/app-model-data';
import { environment } from 'src/environments/environment';
import {  ModalDirective } from 'ngx-bootstrap/modal';
import { ISuccessError,ICommonModal,ICommonModalBody } from './interface-model/app-interface-model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  checkIDSessionInterval: any;
  IdTokenExpiryTimeStamp: any;
  genericModalTitle:string='';isError:boolean=false;navigateTo:string='';langaugeKey:string="";isLangKey:boolean=false;
  clientId: string = environment.CLIENT_ID;
  refreshToken: string = "";
  retryCount: number = 0;
  appSpinner = APP_MODEL_DATA.APP_SPINNER;
  commonModalBody: ICommonModalBody[] = [];commonModalHrefLink:string="";isCommonModalButton:boolean=false;isSignout:boolean=false;
  showInternetConnectionAlertModal = false;
  constructor(private router: Router, public mainService: MainService,
    public translate: TranslateService, public utilService: UtilService, private titleService: Title) {
    this.utilService.checkLanguageConstraintForApp();
    this.refreshToken = this.utilService.getToken('refreshToken');
  }
// Modal declartion
  @ViewChild('successErrorModal') public successErrorModal!:  ModalDirective
  @ViewChild('commonModal') public commanModal!:  ModalDirective
  @ViewChild('internetConnectionAlertModal') public internetConnectionAlertModal!:  ModalDirective

  ngOnInit(): void {
    this.checkInternetConnectionStatus();
    // Setting Site-Page-Title dynamically using ngx-translate service
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(event.translations.lang.siteTitle);
    });
    //Show up the Modal using ngx-bootstrap Modal Directive
    this.utilService.successErrorModal.subscribe((modalData) => {
      if (modalData.modalTitle) {
        this.showSuccessErrorModal(modalData);
      }
    });
    //Show up the common Modal using ngx-bootstrap Modal Directive
    this.utilService.commonModal.subscribe((modalData) => {
      if (modalData.commonModalBody) {
        this.showCommanModal(modalData);
      }
    });
    // Manage header navigation bar
    this.utilService.showNavigationBar.next(true);
    let getAuthcodeData;
    let refreshToken = this.refreshToken;
    this.utilService.getAuthcode.subscribe((response) => {
      getAuthcodeData = response;
      if (getAuthcodeData) {
        this.mainService.exchangeTokenService("authorization_code", getAuthcodeData).subscribe(exchangeTokenSuccess =>{
            let _now = new Date();
            let expireInterval = exchangeTokenSuccess.expires_in;
            _now.setTime(_now.getTime() + 1 * (expireInterval * 1000));
            // based on IdTokenExpiryTimeStamp exchangeToken Api call will happen before 3 minutes of Expriy of Idtoken
            this.IdTokenExpiryTimeStamp = _now.getTime() + 1 * (expireInterval - APP_MODEL_DATA.EXPIRE_INTERVAL_BEFORE * 1000);
            document.cookie = `idToken=${exchangeTokenSuccess.id_token};  expires=${_now.toUTCString()}; path=/;Priority=High;secure;`;
            // add 1 day expiry time for refresh token
            let _nowRefresh = new Date();
            _nowRefresh.setTime(_now.getTime() + 1 * (APP_MODEL_DATA.GET_EXPIRY_TIME * 1000))
            document.cookie = `refreshToken=${exchangeTokenSuccess.refresh_token}; expires=${_nowRefresh.toUTCString()}; path=/;Priority=High;secure;`;
            this.utilService.setLocalStorage("isInitLoad","true");
            this.utilService.getSignedInMessage.next(true);
            this.loadUserSession();
            if (this.checkIDSessionInterval) {
              clearInterval(this.checkIDSessionInterval)
            }
            this.startExchangeTokenInterval();
        });
      }
      else if (refreshToken) {
        this.checkForToken(true);
        if (this.checkIDSessionInterval) {
          clearInterval(this.checkIDSessionInterval);
        }
        this.startExchangeTokenInterval();
      }

    });
  }

  loadUserSession(): void {
    this.mainService.getSessionId().subscribe(getSessionSuccess=>{
      let previousUser = this.utilService.getLocalStoarge("userAccount");
      if (getSessionSuccess && APP_MODEL_DATA.ALLOWED_ROLES.includes(getSessionSuccess.userLevel)) {
        if (previousUser != getSessionSuccess.session_user_id) {
          this.utilService.setLocalStorage("isInitLoad","true"); 
          this.router.navigate(["support-users/all"]);
          this.utilService.setLocalStorage("isInitLoad","false");
        }
        else {
            this.utilService.setLocalStorage("isInitLoad","false");
        }
      }
      else {
        this.utilService.clearStorage();
        let commonModalData: ICommonModal = this.utilService.invalidUserCheck();
        this.showCommanModal(commonModalData);
        return;
      }

      this.utilService.setLocalStorage("userAccount", getSessionSuccess.session_user_id);
      let userAccount = this.utilService.getLocalStoarge("userAccount")!.toString();
      this.mainService.getUser(userAccount).subscribe(getUserSuccess =>{
          if (getUserSuccess.account.disabled) {
            this.utilService.signout();
          }
          else if (getUserSuccess.account) {
            this.utilService.setLocalStorage("UserName", getUserSuccess.account.email);
            this.utilService.showNavigationBar.next(true);
            this.utilService.setLocalStorage("userLevels", getUserSuccess.account.userLevel);
            this.utilService.setLocalStorage("userPermissions", getUserSuccess.account.userPermissions);
            this.utilService.setLocalStorage("rubyUserId", getUserSuccess.account.userId);
            // check for logged in users permission
            if (getUserSuccess.account.userLevel == "support_user") {
              let isUpdateFirmwarePermission = getUserSuccess.account.userPermissions.includes("C000");
              let createUserPermission = getUserSuccess.account.userPermissions.includes("1000");
              let editUserPermission = getUserSuccess.account.userPermissions.includes("2000");
              let managePoolProPermission = getUserSuccess.account.userPermissions.includes("A000");
              this.utilService.setLocalStorage("editUserPermission", editUserPermission);
              this.utilService.setLocalStorage("managePoolProPermission", managePoolProPermission);
              if (createUserPermission) {
                this.utilService.enableCreateUser.next(true);
                this.utilService.usertypes.next(APP_MODEL_DATA.USERS);
              }

              if (managePoolProPermission) {
                this.utilService.enableCreateUser.next(true);
                this.utilService.usertypes.next(APP_MODEL_DATA.POOL_PRO_USERS);
              }

              if (isUpdateFirmwarePermission) {
                this.utilService.isUpdateFirmware.next(true);
              }

              if (managePoolProPermission == true && createUserPermission == true) {
                this.utilService.usertypes.next(APP_MODEL_DATA.SUPPORT_USER_TYPES);
              }
            }
            else {
              this.utilService.enableCreateUser.next(true);
              this.utilService.enableEditUser.next(true);
              this.utilService.isUpdateFirmware.next(true);
              this.utilService.usertypes.next(APP_MODEL_DATA.USER_TYPES);
            }
          }
      });
    });
  }  

  checkForToken(isOnload: boolean) {
    //call exchangeToken API only if client_id and refreshToken is present else clear storage and navigate to signin page.
    if (this.clientId && this.refreshToken) {
     this.mainService.exchangeTokenService("refresh_token").subscribe(exchangeTokenSuccess => {
      let now = new Date();
      let expireInterval = exchangeTokenSuccess.expires_in;
      now.setTime(now.getTime() + 1 * (expireInterval * 1000));
      // based on IdTokenExpiryTimeStamp exchangeToken Api call will happen before 3 minutes of Expriy of Idtoken
      this.IdTokenExpiryTimeStamp = now.getTime() + 1 * (expireInterval - APP_MODEL_DATA.EXPIRE_INTERVAL_BEFORE * 1000);
      document.cookie = `idToken=${exchangeTokenSuccess.id_token};  expires=${now.toUTCString()}; path=/;Priority=High;secure;`;
      this.retryCount = 0; // reset retryCount if success
      // Webtouch session reload
      this.utilService.launchWebTouchOnExchangeToken();
      // Below function will be executed only if page is reloaded or its loaded for first time.
      if (isOnload) {
        this.loadUserSession();
      }
     });
    }
    else {
      this.utilService.clearStorage();
      this.router.navigate(["/sign-in"]);
    }
  }

  startExchangeTokenInterval(): void {
    this.checkIDSessionInterval = setInterval(() => {
      this.refreshToken = this.utilService.getToken('refreshToken');
      if (!this.refreshToken) {
        clearInterval(this.checkIDSessionInterval);
      }
      this.CheckIDSession();
    }, APP_MODEL_DATA.TOKEN_INTERVAL);
  }

  CheckIDSession(): void {
    // check if IdTokenExpiryTimeStamp is less than currentTimeStamp then call exchange token api to get new Id Token
    let currentTimeStamp = Date.now();
    if (this.IdTokenExpiryTimeStamp < currentTimeStamp) {
      this.checkForToken(false);
    }
  }

  /* Modal Functions Start */
  showSuccessErrorModal(successErrorData:ISuccessError): void {
    this.genericModalTitle = successErrorData.modalTitle;
    this.isError = successErrorData.isError;
    this.langaugeKey = successErrorData.langKey;
    this.isLangKey = successErrorData.isLangKey;
    this.successErrorModal.show();
    if (successErrorData.navigateTo) {
      this.navigateTo = successErrorData.navigateTo;
    }
  }
  successErrorModalClose(navigateURL: any) {
    if (navigateURL) {
      this.router.navigate([navigateURL]);
      this.successErrorModal.hide();
    }
    else {
      this.successErrorModal.hide();
    }

  }
  showCommanModal(commonModaldata:ICommonModal): void {
    commonModaldata.commonModalBody.forEach(element => {
      element.content = element.content != '' && element.content != null ? element.content : "Error";
    });
    this.commonModalBody = commonModaldata.commonModalBody;
    this.isCommonModalButton = commonModaldata.isCommonModalButton;
    this.commanModal.show();
  }
  commanModalClose(isSignout:boolean) {
    if(isSignout)
    {
      this.utilService.signout();
      this.commanModal.hide();
    }
    else
    {
      this.commanModal.hide();
    }
  }
 /* Modal Functions End */
 // check Internet Connection Status
  checkInternetConnectionStatus() {
    setInterval(() => {
      if(navigator.onLine === false && !this.showInternetConnectionAlertModal) {
        this.showInternetConnectionAlertModal = true;
        this.internetConnectionAlertModal.show();
      }
      else if(navigator.onLine === true && this.showInternetConnectionAlertModal) {
        window.location.reload();
      }
    },APP_MODEL_DATA.trackInternetConnectionInterval);
  }
}

