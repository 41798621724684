import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SignInModule } from './sign-in/sign-in.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from './feature-modules/shared-module/shared.module';
import {AppHTTPInterceptor} from './app-http-interceptor';
@NgModule({
  declarations: [
    HeaderComponent,
    MyAccountComponent,
    AppComponent
  ],
  imports: [
    BrowserModule,
    SignInModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AppHTTPInterceptor,
    multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
 }

