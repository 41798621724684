<div class="container-fluid router-outlet-container" *ngIf="pageLoaded">
    <h2>{{'lang.user_account' | translate}}</h2>
    <span>{{'lang.user_account_tag' | translate}}</span> : {{userDetails.email}}
    <br>
    <div class="row">
        <div class="col-xs-12 col-sm-8 col-md-4 col-lg-6">
            <form [formGroup]="myAccountForm" (ngSubmit)="updateEditedInfo()">
                <div class="form-group">
                    <h4>{{'lang.required' | translate}}</h4>
                </div>
                <div class="form-group">
                    <label><span>{{'lang.email' | translate}}</span><span>*</span></label>
                    <input class="form-control" formControlName="email"  
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['email'].errors, 'error': myAccountFormControl['email'].errors }">
                </div>

                <div class="form-group">
                    <label><span>{{'lang.time_zone' | translate}}</span><span>*</span></label>
                    <select class="form-control" formControlName="timezone"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['timezone'].errors, 'error': myAccountFormControl['timezone'].errors }">
                        <option *ngFor="let timezone of timezones" [ngValue]="timezone.value">{{timezone.value}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label><span>{{'lang.first_name' | translate}}</span><span>*</span></label>
                    <input class="form-control" formControlName="firstName" type="text"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['firstName'].errors, 'error': myAccountFormControl['firstName'].errors }">
                </div>

                <div class="form-group">
                    <label><span>{{'lang.last_name' | translate}}</span><span>*</span></label>
                    <input class="form-control" formControlName="lastName" type="text"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['lastName'].errors, 'error': myAccountFormControl['lastName'].errors }">
                </div>

                <div class="form-group">
                    <label><span>{{'lang.address1' | translate}}</span><span>*</span></label>
                    <input class="form-control" formControlName="address1" type="text"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['address1'].errors, 'error': myAccountFormControl['address1'].errors }">
                </div>

                <div class="form-group">
                    <label>{{'lang.address2' | translate}}</label>
                    <input class="form-control" formControlName="address2" type="text">
                </div>

                <div class="form-group">
                    <label><span>{{'lang.city' | translate}}</span><span>*</span></label>
                    <input class="form-control" formControlName="city" type="text"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['city'].errors, 'error': myAccountFormControl['city'].errors }">
                </div>

                <div class="form-group">
                    <label><span>{{'lang.state' | translate}}</span><span>*</span></label>
                    <input class="form-control" formControlName="state" type="text"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['state'].errors, 'error': myAccountFormControl['state'].errors }">
                </div>

                <div class="form-group">
                    <label><span>{{'lang.postal_code' | translate}}</span><span>*</span></label>
                    <input class="form-control" formControlName="postal" type="text"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['postal'].errors, 'error': myAccountFormControl['postal'].errors }">
                </div> 

                <div class="form-group">
                    <label><span>{{'lang.country' | translate}}</span><span>*</span></label>
                    <select class="form-control" formControlName="country"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['country'].errors, 'error': myAccountFormControl['country'].errors }">
                        <option *ngFor="let country of countries" [ngValue]="country.code">{{country.display}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>{{'lang.telephone' | translate}}</label>
                    <input class="form-control" formControlName="telephone" type="text"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['telephone'].errors, 'error': myAccountFormControl['telephone'].errors }">
                </div>

                <div class="form-group">
                    <label>{{'lang.change_user_type' | translate}}</label>
                    <input class="form-control" formControlName="userLevel"
                    [ngClass]="{ 'is-invalid': submitted && myAccountFormControl['userLevel'].errors, 'error': myAccountFormControl['userLevel'].errors }">
                </div> 

                <div class="form-group">
                    <button class="btn btn-primary" [disabled]="!myAccountForm.valid">{{'lang.update' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>