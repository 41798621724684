import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_MODEL_DATA } from '../model-data/app-model-data';
import { MainService } from '../services/main.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isCollapsedNavbar = false;
  showNavbar:boolean = false;
  UserName:string = "";
  isUserMenu:boolean = false;
  isDeviceMenu:boolean = false;

  constructor(private router: Router, public utilService: UtilService, public mainService: MainService) { }

  ngOnInit(): void {
    this.utilService.showNavigationBar.subscribe((response) => {
      this.showNavbar = response;
      this.UserName =this.utilService.getLocalStoarge("UserName") ? this.utilService.getLocalStoarge("UserName")!.toString()  : "";
    });
    this.utilService.showActiveMenu.subscribe((response) => {
      this.isUserMenu = response.isUserMenu;
      this.isDeviceMenu = response.isDeviceMenu;
    });
  }
  
  logout(): void {
    this.mainService.gSuiteSignOut().subscribe({ 
      next: () => {
        this.utilService.signout()
      }
    });
  }
  goToMenu(isUserMenu:boolean):void{
    if(isUserMenu)
    {
      this.router.navigate(["support-users/all"]);
    }
    else
    {
      this.router.navigate(["support-devices/all"]);
    }
  }
}
