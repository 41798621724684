import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in.component';
import { SupportPortalComponent } from './support-portal/support-portal.component';

const routes: Routes = [
  { path: "sign-in", component: SignInComponent },
  { path: "support-portal", component: SupportPortalComponent },
  { path: "", redirectTo: "sign-in", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignInRoutingModule { }
