<nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top" *ngIf="showNavbar">
    <a class="navbar-brand" routerLink="/support-users/all">
      <img alt="" src="assets/img/Fluidra_Blue.png">
    </a>
    <button class="navbar-toggler" type="button" (click)="isCollapsedNavbar = !isCollapsedNavbar" [attr.aria-expanded]="!isCollapsedNavbar" aria-controls="collapseNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="collapseNavbar" [collapse]="isCollapsedNavbar" [isAnimated]="true">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': isUserMenu === true}" href="javascript:void(0)" (click)="goToMenu(true)">Users</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': isDeviceMenu === true}" href="javascript:void(0)" (click)="goToMenu(false)">Devices</a>
        </li>
        
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item dropdown" dropdown>
          <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle"
            aria-controls="dropdown-basic">
            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            {{UserName}}
            <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
          role="menu" aria-labelledby="button-basic">
            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)" routerLink="/manage-account-info">My Account</a></li>
          </ul>
        </li>
        <li class="nav-item active">
          <!-- need to add dynamic sign-out click event-->
          <a class="nav-link" href="javascript:void(0)" (click)="logout()">Sign Out</a>
        </li>
      </ul>
    </div>
  </nav>