import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from "../services/main.service";
import { UtilService } from '../services/util.service';
import { APP_MODEL_DATA } from '../model-data/app-model-data';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISuccessError, IUpdatePersonalBody } from '../interface-model/app-interface-model';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  userAccount: any;
  userDetails: any;
  timezones: any;
  countries = APP_MODEL_DATA.COUNTRIES;
  pageLoaded: boolean = false;
  myAccountForm!: FormGroup;
  submitted = false;
  loadSpinner = APP_MODEL_DATA.APP_SPINNER;
  NoWhitespaceStartRegExp = new RegExp(APP_MODEL_DATA.NO_WHITE_SPACES_START_REGX);
  digitsOnlyRegExp = new RegExp(APP_MODEL_DATA.DIGITS_ONLY_REGX);
  
  constructor(private router: Router, 
    public mainService: MainService, 
    private formBuilder: FormBuilder,
    public utilService: UtilService) {
  }

  ngOnInit(): void {
    this.utilService.setMenuActive(false,false);
    this.myAccountForm = this.formBuilder.group({
      email: [{ value: '', disabled: true }, Validators.required],
      timezone: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.pattern(this.NoWhitespaceStartRegExp)]],
      lastName: ['', [Validators.required, Validators.pattern(this.NoWhitespaceStartRegExp)]],
      address1: ['', [Validators.required, Validators.pattern(this.NoWhitespaceStartRegExp)]],
      address2: [''],
      city: ['', [Validators.required, Validators.pattern(this.NoWhitespaceStartRegExp)]],
      state: ['', [Validators.required, Validators.pattern(this.NoWhitespaceStartRegExp)]],
      postal: ['', [Validators.required, Validators.pattern(this.NoWhitespaceStartRegExp),Validators.pattern(this.digitsOnlyRegExp)]],
      country: ['', [Validators.required, Validators.pattern(this.NoWhitespaceStartRegExp)]],
      telephone: ['', [Validators.pattern(this.digitsOnlyRegExp)]],
      userLevel: [{ value: '', disabled: true }]
    })

    this.getUserInfo();
  };

  getUserInfo() {
    this.userAccount = localStorage.getItem("userAccount");
    this.mainService.getUser(this.userAccount).subscribe(getUserSuccess => {      
      this.userDetails = getUserSuccess.account;
      this.timezones = APP_MODEL_DATA.TIMEZONES;
      if (!this.userDetails.timezone) {
        this.userDetails.timezone = "US/Samoa";
      }
      let getCountryName = this.userDetails.country;
      if (getCountryName === undefined) {
        this.userDetails.country = "";
      }
      else {
        this.userDetails.country = this.userDetails.country;
      }

      this.userDetails.userLevel = this.utilService.getUserLevel(this.userDetails);
      this.myAccountForm.setValue({
        email: this.userDetails.email,
        timezone: this.userDetails.timezone,
        firstName: this.userDetails.firstName,
        lastName: this.userDetails.lastName,
        address1: this.userDetails.address1,
        address2: this.userDetails.address2,
        city: this.userDetails.city,
        state: this.userDetails.state,
        postal: this.userDetails.postal,
        country: this.userDetails.country,
        telephone: this.userDetails.telephone,
        userLevel: this.userDetails.userLevel
      });
      this.pageLoaded = true;

    });
  };

  updateEditedInfo() {
    this.submitted = true;
    if (this.myAccountForm.invalid) {
      return;
    } else {
      let myAccountData: IUpdatePersonalBody = {
        "email": this.userDetails.email,
        "password": this.myAccountForm.value.enteredPassword,
        "currentPassword": this.myAccountForm.value.curentPassword,
        "first_name": this.myAccountForm.value.firstName,
        "last_name": this.myAccountForm.value.lastName,
        "address_1": this.myAccountForm.value.address1,
        "address_2": this.myAccountForm.value.address2,
        "city": this.myAccountForm.value.city,
        "state": this.myAccountForm.value.state,
        "time_zone": this.myAccountForm.value.timezone,
        "postal_code": this.myAccountForm.value.postal,
        "country": this.myAccountForm.value.country,
        "phone": this.myAccountForm.value.telephone
      };
      let userData: any = {
        Id: this.userAccount,
        data: myAccountData
      };

      this.mainService.updatePersonalData(userData).subscribe(updatedUserSuccess => {
        localStorage.setItem("myAccount", JSON.stringify(this.myAccountForm.value));
        let saveSuccessData: ISuccessError = {
          modalTitle : 'Success',
          isError : false,
          navigateTo : 'support-users/all',
          langKey : 'lang.modal_saving_success',
          isLangKey : true
        };
        this.utilService.successErrorModal.next(saveSuccessData);
      });
    }
  }

  get myAccountFormControl(): { [key: string]: AbstractControl } {
    return this.myAccountForm.controls;
  }

}
