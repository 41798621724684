export const environment = {
  ENABLE_PROD_MODE: true,
  API_URL: "https://prm.iaqualink.net/v2",
  RUBY_API_URL: "https://prm.iaqualink.net",
  AMAZON_XAPI_KEY:'5X5TEPGURSNPF2XIOI4B2J5DCX86QNB7',
  WEBTOUCH:"https://webtouch.iaqualink.net",
  IAQUA_URL:'https://site.iaqualink.net',
  TOKEN_URL:"https://zodiac.auth.us-east-1.amazoncognito.com/oauth2/token",
  SIGN_IN_AUTH_URL:"https://zodiac.auth.us-east-1.amazoncognito.com/oauth2/authorize",
  CLIENT_ID:"2frafubt3q93v2724b0mn6b87l",
  CLIENT_SECRET_ID:"1c70bf06j0qs618sqbg16vukh8pq52a6g8tbfhljnmcugdql3okd",
  PLATFORM_IO_URL:"https://prod.zodiac-io.com/devices/v2",
  RETRY_ATTEMPTS:2,
  PLATFORM_IO_URL_V1:"https://prod.zodiac-io.com/devices/v1",
  REDIRECT_URL: window.location.origin + "/support-portal",
  SIGN_OUT_AUTH_URL:"https://zodiac.auth.us-east-1.amazoncognito.com/oauth2/revoke",
  PASSWORD_MIN_LENGTH:8,
  PASSWORD_MAX_LENGTH:32,
  V1_API_URL: "https://prm.iaqualink.net/v1",
  MIN_FIRMWARE_VERSION_VSPUMP_SETUP_TAB: "2",
  MIN_FIRMWARE_VERSION_TRUSENSE_TAB: "3",
  MIN_FIRMWARE_VERSION_ICL_TAB: "4",
  HTTP_REQ_TIMEOUT_DURATION: 20000,
  FIRMWARE_DOWNGRADE_ALLOWED: false // Allow downgrading firware to lower versions
};
