<div class="container-fluid signinbody">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-10 col-md-7 col-lg-6 col-xl-5 rounded m-2 text-center">
            <img src="assets/img/Fluidra_Blue.png" alt="" class="logo">
            <div class="box">
                <h2>{{'lang.welcome1' | translate}}</h2>
                <h2>{{'lang.welcome2' | translate}}</h2>
                <button type="button"class="btn signin-btn btn-signin" (click)="callGsuiteLogin()">{{'lang.sign_in' |
                    translate}}</button>
            </div>
            <div class="signin-btm-txt">
                <p>{{'lang.trouble_msg' | translate}}</p>
            </div>
      </div>
    </div>
</div>