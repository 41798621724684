import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-support-portal',
  templateUrl: './support-portal.component.html',
  styleUrls: ['./support-portal.component.css']
})
export class SupportPortalComponent implements OnInit {

  constructor(public utilService: UtilService,public mainService: MainService) { }

  ngOnInit(): void {
    this.utilService.showNavigationBar.next(false);
        // get auth-code from url if present
       let params= this.utilService.getUrlParams();
       if(params["code"])
       {
        this.utilService.getAuthcode.next(params["code"]!);
       }
  }

}
