import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UtilService } from './services/util.service';
import { APP_MODEL_DATA } from './model-data/app-model-data';

@Injectable()
export class AppHTTPInterceptor implements HttpInterceptor {
    appSpinner:string = APP_MODEL_DATA.APP_SPINNER;
    private totalRequests = 0;
    constructor(private utilService: UtilService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.totalRequests++;
        this.utilService.showSpinner(this.appSpinner);
        return next.handle(request).pipe(
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests == 0) {
                 this.utilService.hideSpinner(this.appSpinner);
                }
            })
        );
    }
}
