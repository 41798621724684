export const APP_MODEL_DATA = {
    SUPPORTED_LANG_TRANSLATIONS: ['en', 'es', 'fr', 'it', 'nl', 'pt'],
    
    COUNTRIES: [{
        "code": "af",
        "display": "Afghanistan"
    }, {
        "code": "ax",
        "display": "Åland Islands"
    }, {
        "code": "al",
        "display": "Albania"
    }, {
        "code": "dz",
        "display": "Algeria"
    }, {
        "code": "as",
        "display": "American Samoa"
    }, {
        "code": "ad",
        "display": "Andorra"
    }, {
        "code": "ao",
        "display": "Angola"
    }, {
        "code": "ai",
        "display": "Anguilla"
    }, {
        "code": "aq",
        "display": "Antarctica"
    }, {
        "code": "ag",
        "display": "Antigua and Barbuda"
    }, {
        "code": "ar",
        "display": "Argentina"
    }, {
        "code": "am",
        "display": "Armenia"
    }, {
        "code": "aw",
        "display": "Aruba"
    }, {
        "code": "au",
        "display": "Australia"
    }, {
        "code": "at",
        "display": "Austria"
    }, {
        "code": "az",
        "display": "Azerbaijan"
    }, {
        "code": "bs",
        "display": "Bahamas"
    }, {
        "code": "bh",
        "display": "Bahrain"
    }, {
        "code": "bd",
        "display": "Bangladesh"
    }, {
        "code": "bb",
        "display": "Barbados"
    }, {
        "code": "by",
        "display": "Belarus"
    }, {
        "code": "be",
        "display": "Belgium"
    }, {
        "code": "bz",
        "display": "Belize"
    }, {
        "code": "bj",
        "display": "Benin"
    }, {
        "code": "bm",
        "display": "Bermuda"
    }, {
        "code": "bt",
        "display": "Bhutan"
    }, {
        "code": "bo",
        "display": "Bolivia"
    }, {
        "code": "ba",
        "display": "Bosnia and Herzegovina"
    }, {
        "code": "bw",
        "display": "Botswana"
    }, {
        "code": "bv",
        "display": "Bouvet Island"
    }, {
        "code": "br",
        "display": "Brazil"
    }, {
        "code": "io",
        "display": "British Indian Ocean Territory"
    }, {
        "code": "bn",
        "display": "Brunei Darussalam"
    }, {
        "code": "bg",
        "display": "Bulgaria"
    }, {
        "code": "bf",
        "display": "Burkina Faso"
    }, {
        "code": "bi",
        "display": "Burundi"
    }, {
        "code": "kh",
        "display": "Cambodia"
    }, {
        "code": "cm",
        "display": "Cameroon"
    }, {
        "code": "ca",
        "display": "Canada"
    }, {
        "code": "cv",
        "display": "Cape Verde"
    }, {
        "code": "ky",
        "display": "Cayman Islands"
    }, {
        "code": "cf",
        "display": "Central African Republic"
    }, {
        "code": "td",
        "display": "Chad"
    }, {
        "code": "cl",
        "display": "Chile"
    }, {
        "code": "cn",
        "display": "China"
    }, {
        "code": "cx",
        "display": "Christmas Island"
    }, {
        "code": "cc",
        "display": "Cocos (Keeling) Islands"
    }, {
        "code": "co",
        "display": "Colombia"
    }, {
        "code": "km",
        "display": "Comoros"
    }, {
        "code": "cg",
        "display": "Congo"
    }, {
        "code": "cd",
        "display": "Congo, The Democratic Republic of The"
    }, {
        "code": "ck",
        "display": "Cook Islands"
    }, {
        "code": "cr",
        "display": "Costa Rica"
    }, {
        "code": "ci",
        "display": "Cote D'ivoire"
    }, {
        "code": "hr",
        "display": "Croatia"
    }, {
        "code": "cu",
        "display": "Cuba"
    }, {
        "code": "cy",
        "display": "Cyprus"
    }, {
        "code": "cz",
        "display": "Czech Republic"
    }, {
        "code": "dk",
        "display": "Denmark"
    }, {
        "code": "dj",
        "display": "Djibouti"
    }, {
        "code": "dm",
        "display": "Dominica"
    }, {
        "code": "do",
        "display": "Dominican Republic"
    }, {
        "code": "ec",
        "display": "Ecuador"
    }, {
        "code": "eg",
        "display": "Egypt"
    }, {
        "code": "sv",
        "display": "El Salvador"
    }, {
        "code": "gq",
        "display": "Equatorial Guinea"
    }, {
        "code": "er",
        "display": "Eritrea"
    }, {
        "code": "ee",
        "display": "Estonia"
    }, {
        "code": "et",
        "display": "Ethiopia"
    }, {
        "code": "fk",
        "display": "Falkland Islands (Malvinas)"
    }, {
        "code": "fo",
        "display": "Faroe Islands"
    }, {
        "code": "fj",
        "display": "Fiji"
    }, {
        "code": "fi",
        "display": "Finland"
    }, {
        "code": "fr",
        "display": "France"
    }, {
        "code": "gf",
        "display": "French Guiana"
    }, {
        "code": "pf",
        "display": "French Polynesia"
    }, {
        "code": "tf",
        "display": "French Southern Territories"
    }, {
        "code": "ga",
        "display": "Gabon"
    }, {
        "code": "gm",
        "display": "Gambia"
    }, {
        "code": "ge",
        "display": "Georgia"
    }, {
        "code": "de",
        "display": "Germany"
    }, {
        "code": "gh",
        "display": "Ghana"
    }, {
        "code": "gi",
        "display": "Gibraltar"
    }, {
        "code": "gr",
        "display": "Greece"
    }, {
        "code": "gl",
        "display": "Greenland"
    }, {
        "code": "gd",
        "display": "Grenada"
    }, {
        "code": "gp",
        "display": "Guadeloupe"
    }, {
        "code": "gu",
        "display": "Guam"
    }, {
        "code": "gt",
        "display": "Guatemala"
    }, {
        "code": "gg",
        "display": "Guernsey"
    }, {
        "code": "gn",
        "display": "Guinea"
    }, {
        "code": "gw",
        "display": "Guinea-bissau"
    }, {
        "code": "gy",
        "display": "Guyana"
    }, {
        "code": "ht",
        "display": "Haiti"
    }, {
        "code": "hm",
        "display": "Heard Island and Mcdonald Islands"
    }, {
        "code": "va",
        "display": "Holy See (Vatican City State)"
    }, {
        "code": "hn",
        "display": "Honduras"
    }, {
        "code": "hk",
        "display": "Hong Kong"
    }, {
        "code": "hu",
        "display": "Hungary"
    }, {
        "code": "is",
        "display": "Iceland"
    }, {
        "code": "in",
        "display": "India"
    }, {
        "code": "id",
        "display": "Indonesia"
    }, {
        "code": "ir",
        "display": "Iran, Islamic Republic of"
    }, {
        "code": "iq",
        "display": "Iraq"
    }, {
        "code": "ie",
        "display": "Ireland"
    }, {
        "code": "im",
        "display": "Isle of Man"
    }, {
        "code": "il",
        "display": "Israel"
    }, {
        "code": "it",
        "display": "Italy"
    }, {
        "code": "jm",
        "display": "Jamaica"
    }, {
        "code": "jp",
        "display": "Japan"
    }, {
        "code": "je",
        "display": "Jersey"
    }, {
        "code": "jo",
        "display": "Jordan"
    }, {
        "code": "kz",
        "display": "Kazakhstan"
    }, {
        "code": "ke",
        "display": "Kenya"
    }, {
        "code": "ki",
        "display": "Kiribati"
    }, {
        "code": "kp",
        "display": "Korea, Democratic People's Republic of"
    }, {
        "code": "kr",
        "display": "Korea, Republic of"
    }, {
        "code": "kw",
        "display": "Kuwait"
    }, {
        "code": "kg",
        "display": "Kyrgyzstan"
    }, {
        "code": "la",
        "display": "Lao People's Democratic Republic"
    }, {
        "code": "lv",
        "display": "Latvia"
    }, {
        "code": "lb",
        "display": "Lebanon"
    }, {
        "code": "ls",
        "display": "Lesotho"
    }, {
        "code": "lr",
        "display": "Liberia"
    }, {
        "code": "ly",
        "display": "Libyan Arab Jamahiriya"
    }, {
        "code": "li",
        "display": "Liechtenstein"
    }, {
        "code": "lt",
        "display": "Lithuania"
    }, {
        "code": "lu",
        "display": "Luxembourg"
    }, {
        "code": "mo",
        "display": "Macao"
    }, {
        "code": "mk",
        "display": "Macedonia, The Former Yugoslav Republic of"
    }, {
        "code": "mg",
        "display": "Madagascar"
    }, {
        "code": "mw",
        "display": "Malawi"
    }, {
        "code": "my",
        "display": "Malaysia"
    }, {
        "code": "mv",
        "display": "Maldives"
    }, {
        "code": "ml",
        "display": "Mali"
    }, {
        "code": "mt",
        "display": "Malta"
    }, {
        "code": "mh",
        "display": "Marshall Islands"
    }, {
        "code": "mq",
        "display": "Martinique"
    }, {
        "code": "mr",
        "display": "Mauritania"
    }, {
        "code": "mu",
        "display": "Mauritius"
    }, {
        "code": "yt",
        "display": "Mayotte"
    }, {
        "code": "mx",
        "display": "Mexico"
    }, {
        "code": "fm",
        "display": "Micronesia, Federated States of"
    }, {
        "code": "md",
        "display": "Moldova, Republic of"
    }, {
        "code": "mc",
        "display": "Monaco"
    }, {
        "code": "mn",
        "display": "Mongolia"
    }, {
        "code": "me",
        "display": "Montenegro"
    }, {
        "code": "ms",
        "display": "Montserrat"
    }, {
        "code": "ma",
        "display": "Morocco"
    }, {
        "code": "mz",
        "display": "Mozambique"
    }, {
        "code": "mm",
        "display": "Myanmar"
    }, {
        "code": "na",
        "display": "Namibia"
    }, {
        "code": "nr",
        "display": "Nauru"
    }, {
        "code": "np",
        "display": "Nepal"
    }, {
        "code": "nl",
        "display": "Netherlands"
    }, {
        "code": "an",
        "display": "Netherlands Antilles"
    }, {
        "code": "nc",
        "display": "New Caledonia"
    }, {
        "code": "nz",
        "display": "New Zealand"
    }, {
        "code": "ni",
        "display": "Nicaragua"
    }, {
        "code": "ne",
        "display": "Niger"
    }, {
        "code": "ng",
        "display": "Nigeria"
    }, {
        "code": "nu",
        "display": "Niue"
    }, {
        "code": "nf",
        "display": "Norfolk Island"
    }, {
        "code": "mp",
        "display": "Northern Mariana Islands"
    }, {
        "code": "no",
        "display": "Norway"
    }, {
        "code": "om",
        "display": "Oman"
    }, {
        "code": "pk",
        "display": "Pakistan"
    }, {
        "code": "pw",
        "display": "Palau"
    }, {
        "code": "ps",
        "display": "Palestinian Territory, Occupied"
    }, {
        "code": "pa",
        "display": "Panama"
    }, {
        "code": "pg",
        "display": "Papua New Guinea"
    }, {
        "code": "py",
        "display": "Paraguay"
    }, {
        "code": "pe",
        "display": "Peru"
    }, {
        "code": "ph",
        "display": "Philippines"
    }, {
        "code": "pn",
        "display": "Pitcairn"
    }, {
        "code": "pl",
        "display": "Poland"
    }, {
        "code": "pt",
        "display": "Portugal"
    }, {
        "code": "pr",
        "display": "Puerto Rico"
    }, {
        "code": "qa",
        "display": "Qatar"
    }, {
        "code": "re",
        "display": "Reunion"
    }, {
        "code": "ro",
        "display": "Romania"
    }, {
        "code": "ru",
        "display": "Russian Federation"
    }, {
        "code": "rw",
        "display": "Rwanda"
    }, {
        "code": "sh",
        "display": "Saint Helena"
    }, {
        "code": "kn",
        "display": "Saint Kitts and Nevis"
    }, {
        "code": "lc",
        "display": "Saint Lucia"
    }, {
        "code": "pm",
        "display": "Saint Pierre and Miquelon"
    }, {
        "code": "vc",
        "display": "Saint Vincent and The Grenadines"
    }, {
        "code": "ws",
        "display": "Samoa"
    }, {
        "code": "sm",
        "display": "San Marino"
    }, {
        "code": "st",
        "display": "Sao Tome and Principe"
    }, {
        "code": "sa",
        "display": "Saudi Arabia"
    }, {
        "code": "sn",
        "display": "Senegal"
    }, {
        "code": "rs",
        "display": "Serbia"
    }, {
        "code": "sc",
        "display": "Seychelles"
    }, {
        "code": "sl",
        "display": "Sierra Leone"
    }, {
        "code": "sg",
        "display": "Singapore"
    }, {
        "code": "sk",
        "display": "Slovakia"
    }, {
        "code": "si",
        "display": "Slovenia"
    }, {
        "code": "sb",
        "display": "Solomon Islands"
    }, {
        "code": "so",
        "display": "Somalia"
    }, {
        "code": "za",
        "display": "South Africa"
    }, {
        "code": "gs",
        "display": "South Georgia and The South Sandwich Islands"
    }, {
        "code": "es",
        "display": "Spain"
    }, {
        "code": "lk",
        "display": "Sri Lanka"
    }, {
        "code": "sd",
        "display": "Sudan"
    }, {
        "code": "sr",
        "display": "Suriname"
    }, {
        "code": "sj",
        "display": "Svalbard and Jan Mayen"
    }, {
        "code": "sz",
        "display": "Swaziland"
    }, {
        "code": "se",
        "display": "Sweden"
    }, {
        "code": "ch",
        "display": "Switzerland"
    }, {
        "code": "sy",
        "display": "Syrian Arab Republic"
    }, {
        "code": "tw",
        "display": "Taiwan, Province of China"
    }, {
        "code": "tj",
        "display": "Tajikistan"
    }, {
        "code": "tz",
        "display": "Tanzania, United Republic of"
    }, {
        "code": "th",
        "display": "Thailand"
    }, {
        "code": "tl",
        "display": "Timor-leste"
    }, {
        "code": "tg",
        "display": "Togo"
    }, {
        "code": "tk",
        "display": "Tokelau"
    }, {
        "code": "to",
        "display": "Tonga"
    }, {
        "code": "tt",
        "display": "Trinidad and Tobago"
    }, {
        "code": "tn",
        "display": "Tunisia"
    }, {
        "code": "tr",
        "display": "Turkey"
    }, {
        "code": "tm",
        "display": "Turkmenistan"
    }, {
        "code": "tc",
        "display": "Turks and Caicos Islands"
    }, {
        "code": "tv",
        "display": "Tuvalu"
    }, {
        "code": "ug",
        "display": "Uganda"
    }, {
        "code": "ua",
        "display": "Ukraine"
    }, {
        "code": "ae",
        "display": "United Arab Emirates"
    }, {
        "code": "gb",
        "display": "United Kingdom"
    }, {
        "code": "us",
        "display": "United States"
    }, {
        "code": "um",
        "display": "United States Minor Outlying Islands"
    }, {
        "code": "uy",
        "display": "Uruguay"
    }, {
        "code": "uz",
        "display": "Uzbekistan"
    }, {
        "code": "vu",
        "display": "Vanuatu"
    }, {
        "code": "ve",
        "display": "Venezuela"
    }, {
        "code": "vn",
        "display": "Viet Nam"
    }, {
        "code": "vg",
        "display": "Virgin Islands, British"
    }, {
        "code": "vi",
        "display": "Virgin Islands, U.S."
    }, {
        "code": "wf",
        "display": "Wallis and Futuna"
    }, {
        "code": "eh",
        "display": "Western Sahara"
    }, {
        "code": "ye",
        "display": "Yemen"
    }, {
        "code": "zm",
        "display": "Zambia"
    }, {
        "code": "zw",
        "display": "Zimbabwe"
    }],

    USER_TYPES: [
    {
        "Id": "dev",
        "Name": "Engineering"
    },
    {
        "Id": "poolpro_user",
        "Name": "Pool Pro"
    },
    {
        "Id": "prog",
        "Name": "Programmer"
    },
    {
        "Id": "support",
        "Name": "Support Manager"
    },
    {
        "Id": "support_user",
        "Name": "Support User"
    },
    {
        "Id": "user",
        "Name": "User"
    }],

    DEVICE_TYPES: [
    {
        "Id": "i2d",
        "Name": "VS Pump"
    },
    // EQT-22346 New Device Type Vortrax added
    {
        "Id": "vortrax",
        "Name": "Vortrax"
    },
    {
        "Id": "iaqua",
        "Name": "Automation"
    },
    {
        "Id": "i2d_robot",
        "Name": "i2D Robot"
    },
    {
        "Id": "tcx",
        "Name": "TCX"
    },
    {
        "Id": "vr",
        "Name": "Vortex Refresh"
    },
    {
        "Id": "cyclonext",
        "Name": "Cyclonext"
    },
    {
        "Id": "cyclobat",
        "Name": "Cyclobat"
    },
    {
        "Id": "infinity",
        "Name": "Infinity"
    },
    {
        "Id": "npd1",
        "Name": "NPD1"
    },
    {
        "Id": "npd2",
        "Name": "NPD2"
    },
    {
        "Id": "npd3",
        "Name": "NPD3"
    },
    {
        "Id": "npd4",
        "Name": "NPD4"
    },
    {
        "Id": "hpm",
        "Name": "HPM"
    },
    {
        "Id": "hpc",
        "Name": "HPC"
    },
    {
        "Id": "exo",
        "Name": "EXO"
    },
    {
        "Id": "zs500",
        "Name": "ZS500"
    }],

    SUPPORT_USER_TYPES: [
    {
        "Id": "poolpro_user",
        "Name": "Pool Pro"
    },
    {
        "Id": "user",
        "Name": "User"
    }],

    POOL_PRO_USERS: [
    {
        "Id": "poolpro_user",
        "Name": "Pool Pro"
    }],
    
    USERS: [
    {
        "Id": "user",
        "Name": "User"
    }],

    USER_PERMISSIONS: [{
        "id": "1000",
        "Name": "Create User Account"
    },
    {
        "id": "2000",
        "Name": "Change and Edit User Info"
    },
    {
        "id": "A000",
        "Name": "Manage Pool Pro Users"
    },
    {
        "id": "C000",
        "Name": "Manage Firmware Update"
    }],

    TIMEZONES: [
        {"value":"Africa/Abidjan"},
        {"value":"Africa/Accra"},
        {"value":"Africa/Addis_Ababa"},
        {"value":"Africa/Algiers"},
        {"value":"Africa/Asmara"},
        {"value":"Africa/Asmera"},
        {"value":"Africa/Bamako"},
        {"value":"Africa/Bangui"},
        {"value":"Africa/Banjul"},
        {"value":"Africa/Bissau"},
        {"value":"Africa/Blantyre"},
        {"value":"Africa/Brazzaville"},
        {"value":"Africa/Bujumbura"},
        {"value":"Africa/Cairo"},
        {"value":"Africa/Casablanca"},
        {"value":"Africa/Ceuta"},
        {"value":"Africa/Conakry"},
        {"value":"Africa/Dakar"},
        {"value":"Africa/Dar_es_Salaam"},
        {"value":"Africa/Djibouti"},
        {"value":"Africa/Douala"},
        {"value":"Africa/El_Aaiun"},
        {"value":"Africa/Freetown"},
        {"value":"Africa/Gaborone"},
        {"value":"Africa/Harare"},
        {"value":"Africa/Johannesburg"},
        {"value":"Africa/Juba"},
        {"value":"Africa/Kampala"},
        {"value":"Africa/Khartoum"},
        {"value":"Africa/Kigali"},
        {"value":"Africa/Kinshasa"},
        {"value":"Africa/Lagos"},
        {"value":"Africa/Libreville"},
        {"value":"Africa/Lome"},
        {"value":"Africa/Luanda"},
        {"value":"Africa/Lubumbashi"},
        {"value":"Africa/Lusaka"},
        {"value":"Africa/Malabo"},
        {"value":"Africa/Maputo"},
        {"value":"Africa/Maseru"},
        {"value":"Africa/Mbabane"},
        {"value":"Africa/Mogadishu"},
        {"value":"Africa/Monrovia"},
        {"value":"Africa/Nairobi"},
        {"value":"Africa/Ndjamena"},
        {"value":"Africa/Niamey"},
        {"value":"Africa/Nouakchott"},
        {"value":"Africa/Ouagadougou"},
        {"value":"Africa/Porto-Novo"},
        {"value":"Africa/Sao_Tome"},
        {"value":"Africa/Timbuktu"},
        {"value":"Africa/Tripoli"},
        {"value":"Africa/Tunis"},
        {"value":"Africa/Windhoek"},
        {"value":"America/Adak"},
        {"value":"America/Anchorage"},
        {"value":"America/Anguilla"},
        {"value":"America/Antigua"},
        {"value":"America/Araguaina"},
        {"value":"America/Argentina/Buenos_Aires"},
        {"value":"America/Argentina/Catamarca"},
        {"value":"America/Argentina/ComodRivadavia"},
        {"value":"America/Argentina/Cordoba"},
        {"value":"America/Argentina/Jujuy"},
        {"value":"America/Argentina/La_Rioja"},
        {"value":"America/Argentina/Mendoza"},
        {"value":"America/Argentina/Rio_Gallegos"},
        {"value":"America/Argentina/Salta"},
        {"value":"America/Argentina/San_Juan"},
        {"value":"America/Argentina/San_Luis"},
        {"value":"America/Argentina/Tucuman"},
        {"value":"America/Argentina/Ushuaia"},
        {"value":"America/Aruba"},
        {"value":"America/Asuncion"},
        {"value":"America/Atikokan"},
        {"value":"America/Atka"},
        {"value":"America/Bahia"},
        {"value":"America/Bahia_Banderas"},
        {"value":"America/Barbados"},
        {"value":"America/Belem"},
        {"value":"America/Belize"},
        {"value":"America/Blanc-Sablon"},
        {"value":"America/Boa_Vista"},
        {"value":"America/Bogota"},
        {"value":"America/Boise"},
        {"value":"America/Buenos_Aires"},
        {"value":"America/Cambridge_Bay"},
        {"value":"America/Campo_Grande"},
        {"value":"America/Cancun"},
        {"value":"America/Caracas"},
        {"value":"America/Catamarca"},
        {"value":"America/Cayenne"},
        {"value":"America/Cayman"},
        {"value":"America/Chicago"},
        {"value":"America/Chihuahua"},
        {"value":"America/Coral_Harbour"},
        {"value":"America/Cordoba"},
        {"value":"America/Costa_Rica"},
        {"value":"America/Creston"},
        {"value":"America/Cuiaba"},
        {"value":"America/Curacao"},
        {"value":"America/Danmarkshavn"},
        {"value":"America/Dawson"},
        {"value":"America/Dawson_Creek"},
        {"value":"America/Denver"},
        {"value":"America/Detroit"},
        {"value":"America/Dominica"},
        {"value":"America/Edmonton"},
        {"value":"America/Eirunepe"},
        {"value":"America/El_Salvador"},
        {"value":"America/Ensenada"},
        {"value":"America/Fort_Wayne"},
        {"value":"America/Fortaleza"},
        {"value":"America/Glace_Bay"},
        {"value":"America/Godthab"},
        {"value":"America/Goose_Bay"},
        {"value":"America/Grand_Turk"},
        {"value":"America/Grenada"},
        {"value":"America/Guadeloupe"},
        {"value":"America/Guatemala"},
        {"value":"America/Guayaquil"},
        {"value":"America/Guyana"},
        {"value":"America/Halifax"},
        {"value":"America/Havana"},
        {"value":"America/Hermosillo"},
        {"value":"America/Indiana/Indianapolis"},
        {"value":"America/Indiana/Knox"},
        {"value":"America/Indiana/Marengo"},
        {"value":"America/Indiana/Petersburg"},
        {"value":"America/Indiana/Tell_City"},
        {"value":"America/Indiana/Vevay"},
        {"value":"America/Indiana/Vincennes"},
        {"value":"America/Indiana/Winamac"},
        {"value":"America/Indianapolis"},
        {"value":"America/Inuvik"},
        {"value":"America/Iqaluit"},
        {"value":"America/Jamaica"},
        {"value":"America/Jujuy"},
        {"value":"America/Juneau"},
        {"value":"America/Kentucky/Louisville"},
        {"value":"America/Kentucky/Monticello"},
        {"value":"America/Knox_IN"},
        {"value":"America/Kralendijk"},
        {"value":"America/La_Paz"},
        {"value":"America/Lima"},
        {"value":"America/Los_Angeles"},
        {"value":"America/Louisville"},
        {"value":"America/Lower_Princes"},
        {"value":"America/Maceio"},
        {"value":"America/Managua"},
        {"value":"America/Manaus"},
        {"value":"America/Marigot"},
        {"value":"America/Martinique"},
        {"value":"America/Matamoros"},
        {"value":"America/Mazatlan"},
        {"value":"America/Mendoza"},
        {"value":"America/Menominee"},
        {"value":"America/Merida"},
        {"value":"America/Metlakatla"},
        {"value":"America/Mexico_City"},
        {"value":"America/Miquelon"},
        {"value":"America/Moncton"},
        {"value":"America/Monterrey"},
        {"value":"America/Montevideo"},
        {"value":"America/Montreal"},
        {"value":"America/Montserrat"},
        {"value":"America/Nassau"},
        {"value":"America/New_York"},
        {"value":"America/Nipigon"},
        {"value":"America/Nome"},
        {"value":"America/Noronha"},
        {"value":"America/North_Dakota/Beulah"},
        {"value":"America/North_Dakota/Center"},
        {"value":"America/North_Dakota/New_Salem"},
        {"value":"America/Ojinaga"},
        {"value":"America/Panama"},
        {"value":"America/Pangnirtung"},
        {"value":"America/Paramaribo"},
        {"value":"America/Phoenix"},
        {"value":"America/Port-au-Prince"},
        {"value":"America/Port_of_Spain"},
        {"value":"America/Porto_Acre"},
        {"value":"America/Porto_Velho"},
        {"value":"America/Puerto_Rico"},
        {"value":"America/Rainy_River"},
        {"value":"America/Rankin_Inlet"},
        {"value":"America/Recife"},
        {"value":"America/Regina"},
        {"value":"America/Resolute"},
        {"value":"America/Rio_Branco"},
        {"value":"America/Rosario"},
        {"value":"America/Santa_Isabel"},
        {"value":"America/Santarem"},
        {"value":"America/Santiago"},
        {"value":"America/Santo_Domingo"},
        {"value":"America/Sao_Paulo"},
        {"value":"America/Scoresbysund"},
        {"value":"America/Shiprock"},
        {"value":"America/Sitka"},
        {"value":"America/St_Barthelemy"},
        {"value":"America/St_Johns"},
        {"value":"America/St_Kitts"},
        {"value":"America/St_Lucia"},
        {"value":"America/St_Thomas"},
        {"value":"America/St_Vincent"},
        {"value":"America/Swift_Current"},
        {"value":"America/Tegucigalpa"},
        {"value":"America/Thule"},
        {"value":"America/Thunder_Bay"},
        {"value":"America/Tijuana"},
        {"value":"America/Toronto"},
        {"value":"America/Tortola"},
        {"value":"America/Vancouver"},
        {"value":"America/Virgin"},
        {"value":"America/Whitehorse"},
        {"value":"America/Winnipeg"},
        {"value":"America/Yakutat"},
        {"value":"America/Yellowknife"},
        {"value":"Antarctica/Casey"},
        {"value":"Antarctica/Davis"},
        {"value":"Antarctica/DumontDUrville"},
        {"value":"Antarctica/Macquarie"},
        {"value":"Antarctica/Mawson"},
        {"value":"Antarctica/McMurdo"},
        {"value":"Antarctica/Palmer"},
        {"value":"Antarctica/Rothera"},
        {"value":"Antarctica/South_Pole"},
        {"value":"Antarctica/Syowa"},
        {"value":"Antarctica/Troll"},
        {"value":"Antarctica/Vostok"},
        {"value":"Arctic/Longyearbyen"},
        {"value":"Asia/Aden"},
        {"value":"Asia/Almaty"},
        {"value":"Asia/Amman"},
        {"value":"Asia/Anadyr"},
        {"value":"Asia/Aqtau"},
        {"value":"Asia/Aqtobe"},
        {"value":"Asia/Ashgabat"},
        {"value":"Asia/Ashkhabad"},
        {"value":"Asia/Baghdad"},
        {"value":"Asia/Bahrain"},
        {"value":"Asia/Baku"},
        {"value":"Asia/Bangkok"},
        {"value":"Asia/Beirut"},
        {"value":"Asia/Bishkek"},
        {"value":"Asia/Brunei"},
        {"value":"Asia/Calcutta"},
        {"value":"Asia/Chita"},
        {"value":"Asia/Choibalsan"},
        {"value":"Asia/Chongqing"},
        {"value":"Asia/Chungking"},
        {"value":"Asia/Colombo"},
        {"value":"Asia/Dacca"},
        {"value":"Asia/Damascus"},
        {"value":"Asia/Dhaka"},
        {"value":"Asia/Dili"},
        {"value":"Asia/Dubai"},
        {"value":"Asia/Dushanbe"},
        {"value":"Asia/Gaza"},
        {"value":"Asia/Hanoi"},
        {"value":"Asia/Harbin"},
        {"value":"Asia/Hebron"},
        {"value":"Asia/Ho_Chi_Minh"},
        {"value":"Asia/Hong_Kong"},
        {"value":"Asia/Hovd"},
        {"value":"Asia/Irkutsk"},
        {"value":"Asia/Istanbul"},
        {"value":"Asia/Jakarta"},
        {"value":"Asia/Jayapura"},
        {"value":"Asia/Jerusalem"},
        {"value":"Asia/Kabul"},
        {"value":"Asia/Kamchatka"},
        {"value":"Asia/Karachi"},
        {"value":"Asia/Kashgar"},
        {"value":"Asia/Kathmandu"},
        {"value":"Asia/Katmandu"},
        {"value":"Asia/Khandyga"},
        {"value":"Asia/Kolkata"},
        {"value":"Asia/Krasnoyarsk"},
        {"value":"Asia/Kuala_Lumpur"},
        {"value":"Asia/Kuching"},
        {"value":"Asia/Kuwait"},
        {"value":"Asia/Macao"},
        {"value":"Asia/Macau"},
        {"value":"Asia/Magadan"},
        {"value":"Asia/Makassar"},
        {"value":"Asia/Manila"},
        {"value":"Asia/Muscat"},
        {"value":"Asia/Nicosia"},
        {"value":"Asia/Novokuznetsk"},
        {"value":"Asia/Novosibirsk"},
        {"value":"Asia/Omsk"},
        {"value":"Asia/Oral"},
        {"value":"Asia/Phnom_Penh"},
        {"value":"Asia/Pontianak"},
        {"value":"Asia/Pyongyang"},
        {"value":"Asia/Qatar"},
        {"value":"Asia/Qyzylorda"},
        {"value":"Asia/Rangoon"},
        {"value":"Asia/Riyadh"},
        {"value":"Asia/Saigon"},
        {"value":"Asia/Sakhalin"},
        {"value":"Asia/Samarkand"},
        {"value":"Asia/Seoul"},
        {"value":"Asia/Shanghai"},
        {"value":"Asia/Singapore"},
        {"value":"Asia/Srednekolymsk"},
        {"value":"Asia/Taipei"},
        {"value":"Asia/Tashkent"},
        {"value":"Asia/Tbilisi"},
        {"value":"Asia/Tehran"},
        {"value":"Asia/Tel_Aviv"},
        {"value":"Asia/Thimbu"},
        {"value":"Asia/Thimphu"},
        {"value":"Asia/Tokyo"},
        {"value":"Asia/Ujung_Pandang"},
        {"value":"Asia/Ulaanbaatar"},
        {"value":"Asia/Ulan_Bator"},
        {"value":"Asia/Urumqi"},
        {"value":"Asia/Ust-Nera"},
        {"value":"Asia/Vientiane"},
        {"value":"Asia/Vladivostok"},
        {"value":"Asia/Yakutsk"},
        {"value":"Asia/Yekaterinburg"},
        {"value":"Asia/Yerevan"},
        {"value":"Atlantic/Azores"},
        {"value":"Atlantic/Bermuda"},
        {"value":"Atlantic/Canary"},
        {"value":"Atlantic/Cape_Verde"},
        {"value":"Atlantic/Faeroe"},
        {"value":"Atlantic/Faroe"},
        {"value":"Atlantic/Jan_Mayen"},
        {"value":"Atlantic/Madeira"},
        {"value":"Atlantic/Reykjavik"},
        {"value":"Atlantic/South_Georgia"},
        {"value":"Atlantic/St_Helena"},
        {"value":"Atlantic/Stanley"},
        {"value":"Australia/ACT"},
        {"value":"Australia/Adelaide"},
        {"value":"Australia/Brisbane"},
        {"value":"Australia/Broken_Hill"},
        {"value":"Australia/Canberra"},
        {"value":"Australia/Currie"},
        {"value":"Australia/Darwin"},
        {"value":"Australia/Eucla"},
        {"value":"Australia/Hobart"},
        {"value":"Australia/LHI"},
        {"value":"Australia/Lindeman"},
        {"value":"Australia/Lord_Howe"},
        {"value":"Australia/Melbourne"},
        {"value":"Australia/NSW"},
        {"value":"Australia/North"},
        {"value":"Australia/Perth"},
        {"value":"Australia/Queensland"},
        {"value":"Australia/South"},
        {"value":"Australia/Sydney"},
        {"value":"Australia/Tasmania"},
        {"value":"Australia/Victoria"},
        {"value":"Australia/West"},
        {"value":"Australia/Yancowinna"},
        {"value":"Brazil/Acre"},
        {"value":"Brazil/DeNoronha"},
        {"value":"Brazil/East"},
        {"value":"Brazil/West"},
        {"value":"CET"},
        {"value":"CST6CDT"},
        {"value":"Canada/Atlantic"},
        {"value":"Canada/Central"},
        {"value":"Canada/East-Saskatchewan"},
        {"value":"Canada/Eastern"},
        {"value":"Canada/Mountain"},
        {"value":"Canada/Newfoundland"},
        {"value":"Canada/Pacific"},
        {"value":"Canada/Saskatchewan"},
        {"value":"Canada/Yukon"},
        {"value":"Chile/Continental"},
        {"value":"Chile/EasterIsland"},
        {"value":"Cuba"},
        {"value":"EET"},
        {"value":"EST"},
        {"value":"EST5EDT"},
        {"value":"Egypt"},
        {"value":"Eire"},
        {"value":"Etc/GMT"},
        {"value":"Etc/GMT+0"},
        {"value":"Etc/GMT+1"},
        {"value":"Etc/GMT+10"},
        {"value":"Etc/GMT+11"},
        {"value":"Etc/GMT+12"},
        {"value":"Etc/GMT+2"},
        {"value":"Etc/GMT+3"},
        {"value":"Etc/GMT+4"},
        {"value":"Etc/GMT+5"},
        {"value":"Etc/GMT+6"},
        {"value":"Etc/GMT+7"},
        {"value":"Etc/GMT+8"},
        {"value":"Etc/GMT+9"},
        {"value":"Etc/GMT-0"},
        {"value":"Etc/GMT-1"},
        {"value":"Etc/GMT-10"},
        {"value":"Etc/GMT-11"},
        {"value":"Etc/GMT-12"},
        {"value":"Etc/GMT-13"},
        {"value":"Etc/GMT-14"},
        {"value":"Etc/GMT-2"},
        {"value":"Etc/GMT-3"},
        {"value":"Etc/GMT-4"},
        {"value":"Etc/GMT-5"},
        {"value":"Etc/GMT-6"},
        {"value":"Etc/GMT-7"},
        {"value":"Etc/GMT-8"},
        {"value":"Etc/GMT-9"},
        {"value":"Etc/GMT0"},
        {"value":"Etc/Greenwich"},
        {"value":"Etc/UCT"},
        {"value":"Etc/UTC"},
        {"value":"Etc/Universal"},
        {"value":"Etc/Zulu"},
        {"value":"Europe/Amsterdam"},
        {"value":"Europe/Andorra"},
        {"value":"Europe/Athens"},
        {"value":"Europe/Belfast"},
        {"value":"Europe/Belgrade"},
        {"value":"Europe/Berlin"},
        {"value":"Europe/Bratislava"},
        {"value":"Europe/Brussels"},
        {"value":"Europe/Bucharest"},
        {"value":"Europe/Budapest"},
        {"value":"Europe/Busingen"},
        {"value":"Europe/Chisinau"},
        {"value":"Europe/Copenhagen"},
        {"value":"Europe/Dublin"},
        {"value":"Europe/Gibraltar"},
        {"value":"Europe/Guernsey"},
        {"value":"Europe/Helsinki"},
        {"value":"Europe/Isle_of_Man"},
        {"value":"Europe/Istanbul"},
        {"value":"Europe/Jersey"},
        {"value":"Europe/Kaliningrad"},
        {"value":"Europe/Kiev"},
        {"value":"Europe/Lisbon"},
        {"value":"Europe/Ljubljana"},
        {"value":"Europe/London"},
        {"value":"Europe/Luxembourg"},
        {"value":"Europe/Madrid"},
        {"value":"Europe/Malta"},
        {"value":"Europe/Mariehamn"},
        {"value":"Europe/Minsk"},
        {"value":"Europe/Monaco"},
        {"value":"Europe/Moscow"},
        {"value":"Europe/Nicosia"},
        {"value":"Europe/Oslo"},
        {"value":"Europe/Paris"},
        {"value":"Europe/Podgorica"},
        {"value":"Europe/Prague"},
        {"value":"Europe/Riga"},
        {"value":"Europe/Rome"},
        {"value":"Europe/Samara"},
        {"value":"Europe/San_Marino"},
        {"value":"Europe/Sarajevo"},
        {"value":"Europe/Simferopol"},
        {"value":"Europe/Skopje"},
        {"value":"Europe/Sofia"},
        {"value":"Europe/Stockholm"},
        {"value":"Europe/Tallinn"},
        {"value":"Europe/Tirane"},
        {"value":"Europe/Tiraspol"},
        {"value":"Europe/Uzhgorod"},
        {"value":"Europe/Vaduz"},
        {"value":"Europe/Vatican"},
        {"value":"Europe/Vienna"},
        {"value":"Europe/Vilnius"},
        {"value":"Europe/Volgograd"},
        {"value":"Europe/Warsaw"},
        {"value":"Europe/Zagreb"},
        {"value":"Europe/Zaporozhye"},
        {"value":"Europe/Zurich"},
        {"value":"GB"},
        {"value":"GB-Eire"},
        {"value":"GMT"},
        {"value":"GMT+0"},
        {"value":"GMT-0"},
        {"value":"GMT0"},
        {"value":"Greenwich"},
        {"value":"HST"},
        {"value":"Hongkong"},
        {"value":"Iceland"},
        {"value":"Indian/Antananarivo"},
        {"value":"Indian/Chagos"},
        {"value":"Indian/Christmas"},
        {"value":"Indian/Cocos"},
        {"value":"Indian/Comoro"},
        {"value":"Indian/Kerguelen"},
        {"value":"Indian/Mahe"},
        {"value":"Indian/Maldives"},
        {"value":"Indian/Mauritius"},
        {"value":"Indian/Mayotte"},
        {"value":"Indian/Reunion"},
        {"value":"Iran"},
        {"value":"Israel"},
        {"value":"Jamaica"},
        {"value":"Japan"},
        {"value":"Kwajalein"},
        {"value":"Libya"},
        {"value":"MET"},
        {"value":"MST"},
        {"value":"MST7MDT"},
        {"value":"Mexico/BajaNorte"},
        {"value":"Mexico/BajaSur"},
        {"value":"Mexico/General"},
        {"value":"NZ"},
        {"value":"NZ-CHAT"},
        {"value":"Navajo"},
        {"value":"PRC"},
        {"value":"PST8PDT"},
        {"value":"Pacific/Apia"},
        {"value":"Pacific/Auckland"},
        {"value":"Pacific/Bougainville"},
        {"value":"Pacific/Chatham"},
        {"value":"Pacific/Chuuk"},
        {"value":"Pacific/Easter"},
        {"value":"Pacific/Efate"},
        {"value":"Pacific/Enderbury"},
        {"value":"Pacific/Fakaofo"},
        {"value":"Pacific/Fiji"},
        {"value":"Pacific/Funafuti"},
        {"value":"Pacific/Galapagos"},
        {"value":"Pacific/Gambier"},
        {"value":"Pacific/Guadalcanal"},
        {"value":"Pacific/Guam"},
        {"value":"Pacific/Honolulu"},
        {"value":"Pacific/Johnston"},
        {"value":"Pacific/Kiritimati"},
        {"value":"Pacific/Kosrae"},
        {"value":"Pacific/Kwajalein"},
        {"value":"Pacific/Majuro"},
        {"value":"Pacific/Marquesas"},
        {"value":"Pacific/Midway"},
        {"value":"Pacific/Nauru"},
        {"value":"Pacific/Niue"},
        {"value":"Pacific/Norfolk"},
        {"value":"Pacific/Noumea"},
        {"value":"Pacific/Pago_Pago"},
        {"value":"Pacific/Palau"},
        {"value":"Pacific/Pitcairn"},
        {"value":"Pacific/Pohnpei"},
        {"value":"Pacific/Ponape"},
        {"value":"Pacific/Port_Moresby"},
        {"value":"Pacific/Rarotonga"},
        {"value":"Pacific/Saipan"},
        {"value":"Pacific/Samoa"},
        {"value":"Pacific/Tahiti"},
        {"value":"Pacific/Tarawa"},
        {"value":"Pacific/Tongatapu"},
        {"value":"Pacific/Truk"},
        {"value":"Pacific/Wake"},
        {"value":"Pacific/Wallis"},
        {"value":"Pacific/Yap"},
        {"value":"Poland"},
        {"value":"Portugal"},
        {"value":"ROC"},
        {"value":"ROK"},
        {"value":"Singapore"},
        {"value":"Turkey"},
        {"value":"UCT"},
        {"value":"US/Alaska"},
        {"value":"US/Aleutian"},
        {"value":"US/Arizona"},
        {"value":"US/Central"},
        {"value":"US/East-Indiana"},
        {"value":"US/Eastern"},
        {"value":"US/Hawaii"},
        {"value":"US/Indiana-Starke"},
        {"value":"US/Michigan"},
        {"value":"US/Mountain"},
        {"value":"US/Pacific"},
        {"value":"US/Samoa"},
        {"value":"UTC"},
        {"value":"Universal"},
        {"value":"W-SU"},
        {"value":"WET"},
        {"value":"Zulu"}
    ],

    IQPUMP_UPDATED: [{ status: "LATEST_VERSION_EXISTS" }],

    AUTOMATION_DEVICE_TYPES: ["RS", "PDA", "Z4"], // 0 is RS , 1 is PDA and 2 is Z4
    
    HUMAN_READABLE_AUTOMATION_FIRMWARE_VERSIONS: [
        {"version": "1.29", "name": "Rev T.2", "chipType": "PIC"}, 
        {"version": "1.39", "name": "Rev T.2g", "chipType": "GIGA"},
        {"version": "2.29", "name": "Rev W", "chipType": "PIC"},
        {"version": "2.39", "name": "Rev Wg", "chipType": "GIGA"},
        {"version": "3.29", "name": "Rev X", "chipType": "PIC"},
        {"version": "3.39", "name": "Rev Xg", "chipType": "GIGA"},
        {"version": "4.29", "name": "Rev Y", "chipType": "PIC"},
        {"version": "4.39", "name": "Rev Yg", "chipType": "GIGA"}
    ],
    
    CONFIGURATION_TYPES: [
        {"value": "0", "type": "P/S combo"},
        {"value": "1", "type": "Pool-only"},
        {"value": "2", "type": "Dual Equipment"}
    ],
    
    NUMBER_OF_RELAYS: ["4","6","8","10","12","14","16"],
    
    VSPUMP_APPLICATION_NAMES: [
        {"appId": "0", "appName": "Not Installed", "systemType": [0, 1, 2]},
        {"appId": "1", "appName": "Filtration", "systemType": [0, 1]},
        {"appId": "2", "appName": "Pool Pump", "systemType": [2]},
        {"appId": "3", "appName": "Spa Pump", "systemType": [2]},
        {"appId": "4", "appName": "Aux Pump", "systemType": [0, 1, 2]}
    ],
    EXPIRE_INTERVAL_BEFORE : 180, // 3 minutes in seconds
    GET_EXPIRY_TIME: 86400, // 1 day expiry time for refresh token
    TOKEN_INTERVAL:30000,
    RETRY_TIMEOUT:10000,
    ALLOWED_ROLES : ['support_user', 'support'],
    APP_SPINNER: 'app-spinner',
    CURRENT_PAGE: 1,
    ITEMS_PER_PAGE:20,
    MAXIMUM_PAGE_SIZE:9,
    RETRY_ATTEMPTS: 2,
    SIGN_IN_MSG_TIMEOUT: 5000,
    DEFAULT_OFFSET: 0,
    DEFAULT_COLUMN_NAME: 'created_at',
    SORT_DESC: 'desc',
    SORT_ASC: 'asc',
    PAGINATION_OFFSET: 20,
    ROLE_SUPPORT_USER: 'support_user',
    ROLE_SUPPORT: 'support',
    ROLE_USER: 'user',
    ROLE_POOLPRO: 'poolpro_user',
    NEW_DEVICES_HPM_HPC_EXO_ZS500: ["hpm", "hpc", "exo", "zs500"],
    NEW_PLATFORM_DEVICES : ["tcx","vr","cyclobat","cyclonext","vortrax"],
    AUTOMATION: 'Automation',
    HPM: 'HPM',
    HPC: 'HPC',
    EXO: 'EXO',
    ZS500: 'ZS500',
    TCX: 'TCX',
    VORTEX_REFRESH: 'Vortex Refresh',
    CYCLOBAT: 'Cyclobat',
    VORTRAX: 'Vortrax',
    CYCLONEXT: 'Cyclonext',
    VS_PUMP: 'VS Pump',
    i2D_ROBOT : "i2D Robot",
    RS_DEVICE: "0",
    PDA_DEVICE: "1",
    Z4_DEVICE: "2",
    LANG_VERSION: '2.5',
    TRACK_WEBTOUCH_TIMER: 2000,
    UPDATE_FIRMWARE_CHIP_TYPE:[
        {
            "Id": "PIC",
            "Name": "PIC"
        },
        {
            "Id": "GIGA",
            "Name": "GIGA"
        }],
    PIC_CHIP_TYPE:"PIC",
    GIGA_CHIP_TYPE:"GIGA",
    TEST_TYPE:"0",
    BURN_TYPE:"1",
    INFINITE_WATER_COLOR:"Infinte Water Color",
    TRUSENSE: "TruSense",
    MIN_RANGE_600: 600,
    MAX_RANGE_3450: 3450,
    MIN_VSP_PRIME_DURATION: 0,
    MAX_VSP_PRIME_DURATION: 5,
    MASTER_SPEEDS: {
        MINIMUM_SPEED: "Minimum Speed",
        MAXIMUM_SPEED: "Maximum Speed",
        PRIMING_SPEED: "Priming Speed",
        PRIMING_DURATION: "Priming Duration",
        FREEZE_PROTECT_SPEED: "Freeze Protect Speed"
    },
    trackInternetConnectionInterval: 2000,
    NO_WHITE_SPACES_START_REGX: "\\S",
    DIGITS_ONLY_REGX: "^[0-9]*$",
    ERROR: "ERROR",
    RUBY_USER_ID: "rubyUserId",
    CONNECTED: "connected",
    DISCONNECTED: "disconnected",
    ONLINE: "Online",
    OFFLINE: "Offline",
    PLATFORM_DEVICES_V2_APIKEYANDSECRET: "/platform/devices/v2/apiKeyAndSecret",
    RUBY_API_KEY: "RUBY_API_KEY",
    SCHEDULES: {
        MIN_SUPPORTED_RPM: 600,
        MAX_SUPPORTED_RPM: 3450,
        START_HOUR: 0,
        END_HOUR: 23,
        SUNDAY: 'Su',
        MONDAY: 'M',
        TUESDAY: 'Tu',
        WEDNESDAY: 'W',
        THURSDAY: 'Th',
        FRIDAY: 'F',
        SATURDAY: 'Sa',
        DEFAULT_RPM_VALUE: 1725,
        DEFAULT_NEW_SCHEDULE_VALUE: 'Y080016001725YYYYYYYDefault Schedule',
        DAYS_ARRAY: [{
            label: 'Sunday',
            checked: true
        }, {
            label: 'Monday',
            checked: true
        }, {
            label: 'Tuesday',
            checked: true
        }, {
            label: 'Wednesday',
            checked: true
        }, {
            label: 'Thursday',
            checked: true
        }, {
            label: 'Friday',
            checked: true
        }, {
            label: 'Saturday',
            checked: true
        }],
        DEFAULT_RPM_INCREMENT: 25,
        HOURS_FORMAT_12: 12,
        ONLINE: 'Online',
        OFFLINE: 'Offline'
    },
}