import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { UtilService } from '../services/util.service';
import { environment } from 'src/environments/environment';
import {ICommonModal } from '../interface-model/app-interface-model';
import { APP_MODEL_DATA } from '../model-data/app-model-data';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  constructor(public router: Router,
    public mainService: MainService,
    public activatedRoute: ActivatedRoute,
    public utilService: UtilService) {
    this.utilService.checkLanguageConstraintForSignIn();
  }
  isIdTokenPresent = this.utilService.getToken('idToken');
  refreshToken = this.utilService.getToken('refreshToken');
  appSpinner = APP_MODEL_DATA.APP_SPINNER;
  
  ngOnInit(): void {
    this.utilService.showNavigationBar.next(false);
  // IOTC-4158 To check in If condition Idtoken not present and only refresh token present calls checkExchangeToken function
  //  IOTC-4158 To check in else if condition when Idtoken present then check for that user then redirect to users page
    if (!this.isIdTokenPresent && this.refreshToken) {
      this.checkExchangeToken();
    } else if (this.isIdTokenPresent) {
      this.checkSessionId();
    }
  }

  // IOTC-4158 To call refresh Token API to get new Idtoken and redirect on success to users page
  checkExchangeToken() {
    this.mainService.exchangeTokenService("refresh_token").subscribe(exchangeTokenSuccess => {
      let now = new Date();
      let expireInterval = exchangeTokenSuccess.expires_in;
      now.setTime(now.getTime() + 1 * (expireInterval * 1000));
      document.cookie = `idToken=${exchangeTokenSuccess.id_token};  expires=${now.toUTCString()}; path=/;Priority=High;secure;`;
      this.mainService.getSessionId().subscribe(getSessionSuccess=>{
        if (getSessionSuccess.session_user_id && APP_MODEL_DATA.ALLOWED_ROLES.includes(getSessionSuccess.userLevel)) {
          this.router.navigate(["support-users/all"]);
        }
        else {
          this.utilService.clearStorage();
        }
      });
    });
  }
  checkSessionId()
  {
    this.mainService.getSessionId().subscribe(getSessionSuccess =>{
      this.utilService.setLocalStorage("userAccount", getSessionSuccess.session_user_id);
      if (getSessionSuccess.session_user_id && APP_MODEL_DATA.ALLOWED_ROLES.includes(getSessionSuccess.userLevel)) {
        this.router.navigate(["support-users/all"]);
      }
      else {
        this.utilService.clearStorage();
      }
    });
  }

  // IOTC-4157 GSuite Integration By calling AWS congnito to get Authcode
  callGsuiteLogin(): void {
    this.utilService.showSpinner(this.appSpinner);
    window.location.href = environment.SIGN_IN_AUTH_URL + "?identity_provider=Google&redirect_uri=" + environment.REDIRECT_URL + "&response_type=CODE&client_id=" + environment.CLIENT_ID + "&scope=email%20openid%20profile";
  }

}
