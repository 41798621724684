import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInRoutingModule } from './sign-in-routing.module';
import { SignInComponent } from './sign-in.component';
import { SupportPortalComponent } from './support-portal/support-portal.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { langTranslateHttpLoader } from '../lang-translate.loader';
import { HttpClient } from '@angular/common/http';


@NgModule({
  declarations: [
    SignInComponent,
    SupportPortalComponent
  ],
  imports: [
    CommonModule,
    SignInRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: langTranslateHttpLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class SignInModule { }
