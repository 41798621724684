import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { langTranslateHttpLoader } from 'src/app/lang-translate.loader';
import { RemoveDeviceComponent } from './components/remove-device/remove-device.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
    declarations:[
        RemoveDeviceComponent
    ],
    imports:[
        CommonModule, // for getting acces to all the realtime directives in Angular like ngIf , ngFor etc...
        ReactiveFormsModule,
        FormsModule,
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: langTranslateHttpLoader,
                deps: [HttpClient]
            }
        }),
        TimepickerModule.forRoot()
    ],
    exports:[
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        RemoveDeviceComponent,
        ModalModule,
        PaginationModule,
        TimepickerModule
    ]
})
export class SharedModule {

}