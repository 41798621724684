import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MyAccountComponent } from './my-account/my-account.component';
import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
  { path: "support-users",
    loadChildren: () => import('./feature-modules/support-users/support-users.module').then(m => m.SupportUsersModule)
  },
  { path: "support-devices", 
    loadChildren: () => import('./feature-modules/support-devices/support-devices.module').then(m => m.SupportDevicesModule)
  },
  { path: "manage-account-info", component: MyAccountComponent },
  { path: "**", component: SignInComponent }, 
  // Updated the changes to redirect user to sign in page if end user has Angular JS URL bookmarked
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }