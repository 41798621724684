import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, Observable, of, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { ICommonModal, IGetAllUsersBody, IGetUserDevices, ISaveuserDataBody, ISearchDataBody,IUpdateUserDataBody } from '../interface-model/app-interface-model';
import { APP_MODEL_DATA } from '../model-data/app-model-data';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  headers = new HttpHeaders();
  domain = environment.API_URL;
  appSpinner = APP_MODEL_DATA.APP_SPINNER;
  
  constructor(private http: HttpClient,public utilService: UtilService,public translate: TranslateService) { }
  exchangeTokenService(grantType: string,authCode?: string): Observable<any> {
    let apiUrl = '';
    let client_id = environment.CLIENT_ID;
    let refreshToken = this.utilService.getToken('refreshToken');
    let authorizationHeader = 'Basic ' + window.btoa(environment.CLIENT_ID+":"+environment.CLIENT_SECRET_ID);
    if (grantType === 'authorization_code') {
        apiUrl = `${environment.TOKEN_URL}?grant_type=${grantType}&code=${authCode}&redirect_uri=${environment.REDIRECT_URL}`;
    }
    else if(grantType === "refresh_token") {
      apiUrl = `${environment.TOKEN_URL}?grant_type=${grantType}&client_id=${client_id}&refresh_token=${refreshToken}`;
    }
    return this.http.post(apiUrl,
    '',
    {
     headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': authorizationHeader
        }
    }).pipe(
      catchError((err) => {
        if (err.error.error == 'invalid_grant') {
          this.utilService.clearStorage();
          let commonModalData: ICommonModal =this.utilService.expriySessionCheck();
          this.utilService.commonModal.next(commonModalData);
        }
        else {
          this.utilService.clearStorage();
          let commonModalData: ICommonModal =this.utilService.exchangeErrorCheck(err.error.error);
          this.utilService.commonModal.next(commonModalData);
        }
        return throwError(()=>err);
      })
    )
  }
  
  getUser(userId: string): Observable<any>  {
    return this.http.get(`${this.domain}/users/${userId}/account`,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
         }
     }).pipe(
      catchError((getUserError) => {
        this.utilService.commonErrorHandler(getUserError);
        return throwError(()=>getUserError);
      }));
};
  getSessionId(): Observable<any> {
    return this.http.get(`${this.domain}/userId`,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
         }
     }).pipe(
      catchError((getSessionError) => {
        this.utilService.commonErrorHandler(getSessionError);
        return throwError(()=>getSessionError);
      }));
};

  getAllUsers(offset: number, sortKey: string, sort: string): Observable<any> {

    let getUsersBodyData: IGetAllUsersBody = { offset, sortKey, sort };

    return this.http.post(`${environment.API_URL}/users/all`, getUsersBodyData, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getUsersError) => {
        this.utilService.commonErrorHandler(getUsersError);
        return throwError(() => getUsersError);
      }));
  };

  updatePersonalData(userData: any): Observable<any> {
    const userId = userData.Id;

    return this.http.post(`${this.domain}/users/${userId}/update_personal`, userData.data, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((updatedUserError) => {
        this.utilService.commonErrorHandler(updatedUserError);
        return throwError(() => updatedUserError);
      }));
  };

  // IOTC-4160 - G suite Sign out
  gSuiteSignOut(): Observable<any> {
    let retryAttempts = 0;
    let refreshToken = this.utilService.getToken('refreshToken');
    //authorizationHeader is the base64 conversion of <client_id>:<client_secret>
    let authorizationHeader = 'Basic ' + window.btoa(environment.CLIENT_ID+":"+environment.CLIENT_SECRET_ID);
    let apiUrl = `${environment.SIGN_OUT_AUTH_URL}?token=${refreshToken}`;
    return this.http.post(apiUrl,
      '',
      {
       headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': authorizationHeader
          }
      }).pipe(
        catchError((err) => {
          retryAttempts++
          if(err.status === 400 && err?.error?.errorcode == 'ThrottlingException' && retryAttempts <= APP_MODEL_DATA.RETRY_ATTEMPTS) {
            return throwError(() => err); // this will trigger retry operator
          }
          else {
            this.utilService.signout();
            return of(err);
          }
        }),
        retry(APP_MODEL_DATA.RETRY_ATTEMPTS)
      )
  }

  saveUserData(userData: ISaveuserDataBody): Observable<any> {
    return this.http.post(`${this.domain}/signup`, userData, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((saveUserError) => {
        this.utilService.commonErrorHandler(saveUserError);
        return throwError(() => saveUserError);
      }));
  };

  searchUserData(searchTerm: string, offset: number, sortKey: string, sort: string, advancedSearch: boolean): Observable<any> {

    let searchData: ISearchDataBody = { searchTerm, offset, sortKey, sort, advancedSearch };

    return this.http.post(`${this.domain}/users/search`, searchData, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((searchUserError) => {
        this.utilService.commonErrorHandler(searchUserError);
        return throwError(() => searchUserError);
      }));
  }

  updateUserData(updateUserData: IUpdateUserDataBody,userId: string): Observable<any> {
    return this.http.post(`${this.domain}/users/${userId}/update`, updateUserData, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((updateUserError) => {
        this.utilService.commonErrorHandler(updateUserError);
        return throwError(() => updateUserError);
      }));
  };

  getApiKey(key: string | undefined): Observable<any> {
    return this.http.get(`${environment.RUBY_API_URL}/secret/v1/list?keys=${key}`,{
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': environment.AMAZON_XAPI_KEY
        }
     }).pipe(
      catchError((getApiKeyError) => {
        getApiKeyError.error.messages= [{
          text: 'Error',
          type: 'error',
        }];
        this.utilService.commonErrorHandler(getApiKeyError);
        return throwError(()=>getApiKeyError);
      }));
  };

  addNewPlatformDevice(deviceId: string,deviceBody: any): Observable<any> {   
    deviceBody = JSON.stringify(deviceBody);    
    return this.http.post(`${environment.PLATFORM_IO_URL}/${deviceId}/add`, deviceBody, {
      headers: {
        'Authorization': this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((deviceNewDataError) => {
        if(deviceNewDataError.status != 401 && deviceNewDataError.status != 403)
        {
          if (deviceNewDataError.error.msg == "request is invalid; device does not exist") 
          {
            deviceNewDataError.error.messages= [{
                text: 'device_type_error',
                type: 'error',
              }];
          }
          else
          {
            deviceNewDataError.error.messages= [{
              text: 'Error',
              type: 'error',
            }];
          }
        }
        this.utilService.commonErrorHandler(deviceNewDataError);
        return throwError(() => deviceNewDataError);
      }));
  };

  addLegacyDevice(deviceId: string,deviceBody: any): Observable<any> {   
    return this.http.post(`${this.domain}/device/${deviceId}/add_device`, deviceBody, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((deviceDataError) => {
        this.utilService.commonErrorHandler(deviceDataError);
        return throwError(() => deviceDataError);
      }));
  };
  
  getUserDevices(userData: IGetUserDevices): Observable<any> {
    return this.http.post(`${this.domain}/users/${userData.userId}/devices`, userData.data, {
    headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
    catchError((userDevicesError) => {
        this.utilService.commonErrorHandler(userDevicesError);
        return throwError(() => userDevicesError);
      }));
  };

  removeNewPlatformDevice(deviceId: string, deviceBody: any): Observable<any> {

    return this.http.post(`${environment.PLATFORM_IO_URL_V1}/${deviceId}/remove`, deviceBody, {
      headers: {
        'Authorization': this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((removeNewPlatformDeviceError) => {
        this.utilService.commonErrorHandler(removeNewPlatformDeviceError);
        return throwError(() => removeNewPlatformDeviceError);
      }));
  };

  removeLegacyDevice(deviceId: string, deviceBody: any): Observable<any> {

    return this.http.post(`${this.domain}/device/${deviceId}/delete`, deviceBody, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((removeLegacyDeviceError) => {
        this.utilService.commonErrorHandler(removeLegacyDeviceError);
        return throwError(() => removeLegacyDeviceError);
      }));
  };
  

  getAllDevices(offset: number, sortKey: string, sort: string): Observable<any> {

    let getDevicesBodyData: IGetAllUsersBody = { offset, sortKey, sort };

    return this.http.post(`${this.domain}/devices/all`, getDevicesBodyData, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getDevicesError) => {
        this.utilService.commonErrorHandler(getDevicesError);
        return throwError(() => getDevicesError);
      }));
  };
  getUserActivity(offset: number,sortKey: string,sort: string,userId:string): Observable<any> {
    let body = {
      offset,
      sortKey,
      sort
    };
    return this.http.post(`${this.domain}/users/${userId}/activity`, body, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((updateUserError) => {
        this.utilService.commonErrorHandler(updateUserError);
        return throwError(() => updateUserError);
      }));
  };

  getDeviceInfo(deviceId:string, userId:string): Observable<any> {
    return this.http.get(`${this.domain}/devices/${deviceId}/info?userId=${userId}`,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
         }
     }).pipe(
      catchError((getDeviceInfoError) => {
        let commonModalData: ICommonModal =this.utilService.deviceInfoErrorCheck(this.translate.instant('lang.device_not_registered'));
        this.utilService.commonModal.next(commonModalData);
        return throwError(()=>getDeviceInfoError);
      }));
  };

  // Get Home api call
  getHome(deviceSerialNumber:string): Observable<any> {
    return this.http.get(`${this.domain}/mobile/session.json?command=get_home&serial=${deviceSerialNumber}&actionID=command&attached_test=true&country=us`,{
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
         }
     }).pipe(
      catchError((getHomeError) => {
        this.utilService.commonErrorHandler(getHomeError);
        return throwError(() => getHomeError);
      }));
  };

  searchDeviceData(searchTerm: string, offset: number, sortKey: string, sort: string): Observable<any> {

    let searchData: ISearchDataBody = { searchTerm, offset, sortKey, sort };

    return this.http.post(`${this.domain}/devices/search`, searchData, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((searchDeviceError) => {
        this.utilService.commonErrorHandler(searchDeviceError);
        return throwError(() => searchDeviceError);
      }));
  };

  getTCXShadow(deviceSerialNumber: string, signature: string): Observable<any> {

    return this.http.get(`${environment.PLATFORM_IO_URL}/${deviceSerialNumber}/shadow?signature=${signature}`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getTCXShadowError) => {
        this.utilService.commonErrorHandler(getTCXShadowError);
        return throwError(() => getTCXShadowError);
      }));
  };

  getTCXDeviceInfo(deviceSerialNumber: string, signature: string, deviceType: string, firmwareVersion: string) {
    deviceType = deviceType.toLowerCase(); // while passing to api 'tcx' should be lowercase
    return this.http.get(`${environment.PLATFORM_IO_URL}/${deviceSerialNumber}/info?signature=${signature}&deviceType=${deviceType}&currentFirmware=${firmwareVersion}`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getTCXDeviceInfoError) => {
        this.utilService.commonErrorHandler(getTCXDeviceInfoError);
        return throwError(() => getTCXDeviceInfoError);
      }));
  };
  

  updateDeviceName(userId :string, deviceId:string, name:string): Observable<any> {
    let body = {
      userId,
      name
    }
    return this.http.post(`${this.domain}/device/${deviceId}/name`, body, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((updateDeviceNameError) => {
        this.utilService.commonErrorHandler(updateDeviceNameError);
        return throwError(() => updateDeviceNameError);
      }));
  };

  // CX Portal | EQT-22531: Version update for IQ30 Firmware Anntena 
    updateFirwareVersion(deviceSerialNumber: string, deviceType: string, user: string){
      let body= {
        "device_type": deviceType ,
        "user_email": user
      }
      return this.http.post(`${this.domain}/devices/${deviceSerialNumber}/iot_ota`, body, {
        headers: {
          'Authorization': 'Bearer ' + this.utilService.getToken('idToken'),
        }
      }).pipe(
        catchError((updateFirwareVersionError) => {
          this.utilService.commonErrorHandler(updateFirwareVersionError);
          return throwError(() => updateFirwareVersionError);
        }));
    };

  // CX Portal | EQT-22531: Device type check
    deviceSubType(deviceSerialNumber: string){
      let body= {
        "deviceIdList":[deviceSerialNumber] ,
      }
      return this.http.post(`${environment.PLATFORM_IO_URL_V1}/details`, body, {
        headers: {
          'Authorization': 'Bearer ' + this.utilService.getToken('idToken'),
        }
      }).pipe(
        catchError((deviceSubTypeError) => {
          this.utilService.commonErrorHandler(deviceSubTypeError);
          return throwError(() => deviceSubTypeError);
        }));
    };

  getDeviceUserList(deviceId: string): Observable<any> {
    return this.http.get(`${this.domain}/device/${deviceId}/users`,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
         }
     }).pipe(
      catchError((getDeviceUserListError) => {
        this.utilService.commonErrorHandler(getDeviceUserListError);
        return throwError(()=>getDeviceUserListError);
      }));
  };

  postCommand(deviceId:string, command:string): Observable<any> {
    let body = {
      "command":command
    }
    return this.http.post(`${this.domain}/devices/${deviceId}/post_command`, body, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((postCommandError) => {
        this.utilService.commonErrorHandler(postCommandError);
        return throwError(() => postCommandError);
      }));
  };

  getVersions(): Observable<any> {
    return this.http.get(`${this.domain}/firmware/versions`,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
         }
     }).pipe(
      catchError((getVersionsError) => {
        this.utilService.commonErrorHandler(getVersionsError);
        return throwError(() => getVersionsError);
      }));
  };

  getDevicesCommandApi(serailNumber:string): Observable<any> {
    return this.http.get(`${this.domain}/mobile/session.json?command=get_devices&serial=${serailNumber}&actionID=command`,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
         }
     }).pipe(
      catchError((getDevicesCommand) => {
        if(getDevicesCommand.error == "")
        {
          getDevicesCommand.error.messages= [{
            text: 'Error',
            type: 'error',
          }];
        }
        else
        {
          this.utilService.commonErrorHandler(getDevicesCommand);
        }
        return throwError(() => getDevicesCommand);
      }));
  };

  transferFirmware(deviceId:string, transfer_firmware:any): Observable<any> {
    let body = {
      "firmware": transfer_firmware.versionname,
      "transferType": transfer_firmware.type,
      "firmwareAddress": transfer_firmware.address,
      "firmwareTypeId": transfer_firmware.typeID
    }
    return this.http.post(`${this.domain}/devices/${deviceId}/transfer_firmware`, body, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((transferFirmwareError) => {
        this.utilService.commonErrorHandler(transferFirmwareError);
        return throwError(() => transferFirmwareError);
      }));
  };

  // call getVSPumpNames and getVSPumpApplicationsModelsAndSerials
  getVSPumpNamesAndApplicationsModelsAndSerials(deviceSerial:string): Observable<any> {
    let setHeaders = {'Authorization': 'Bearer ' + this.utilService.getToken('idToken')};
    let getVSPumpNames = this.http.get(`${environment.API_URL}/mobile/session.json?command=get_vsp_names&serial=${deviceSerial}&actionID=command`, {headers: setHeaders});
    let getVSPumpApplicationsModelsAndSerials = this.http.get(`${environment.API_URL}/mobile/session.json?command=get_vsp_appmodelserials&serial=${deviceSerial}&actionID=command`, {headers: setHeaders});
     
    const arr = [getVSPumpNames, getVSPumpApplicationsModelsAndSerials];
    return forkJoin(arr).pipe(
      catchError((errorRes) => {
        this.utilService.commonErrorHandler(errorRes);
        return throwError(() => errorRes);
      })
    );
  }
  upgradeDevice(deviceId:string, device:any): Observable<any> {
    device = JSON.stringify(device);
    return this.http.post(`${this.domain}/device/${deviceId}/upgrade_device`, device, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((upgradeDeviceError) => {
        this.utilService.commonErrorHandler(upgradeDeviceError);
        return throwError(() => upgradeDeviceError);
      }));
  };

  getInfiniteWaterColorsInfo(deviceSerialNumber: string): Observable<any> {
    return this.http.get(`${this.domain}/mobile/session.json?command=get_icl_info&serial=${deviceSerialNumber}&actionID=command`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getInfiniteWaterColorsInfoError) => {
          this.utilService.commonErrorHandler(getInfiniteWaterColorsInfoError);
        return throwError(() => getInfiniteWaterColorsInfoError);
      }));
  };
    // get VS Pump Detailed info for Master Speeds , Feature Speeds and Feature Speeds Aux Assignment Names
  getVSPumpDetailedInfo(deviceSerial: string, pumpId: number): Observable<any> {
    let setHeaders = { 'Authorization': 'Bearer ' + this.utilService.getToken('idToken') };
    let getVSPumpMasterSpeeds = this.http.get(`${environment.API_URL}/mobile/session.json?command=get_vsp_definition&serial=${deviceSerial}&actionID=command&slot_id=${pumpId}`, { headers: setHeaders });
    let getVSPumpFeatureSpeeds = this.http.get(`${environment.API_URL}/mobile/session.json?command=get_vsp_speedauxinfo&serial=${deviceSerial}&actionID=command&slot_id=${pumpId}`, { headers: setHeaders });
    let getVSPumpFeatureSpeedsAuxAssignmentNames = this.http.get(`${environment.API_URL}/mobile/session.json?command=get_devices&serial=${deviceSerial}&actionID=command`, { headers: setHeaders });

    const arr = [getVSPumpMasterSpeeds, getVSPumpFeatureSpeeds, getVSPumpFeatureSpeedsAuxAssignmentNames];
    return forkJoin(arr).pipe(
      catchError((errorRes) => {
        this.utilService.commonErrorHandler(errorRes);
        return throwError(() => errorRes);
      })
    );
  }

  getDeviceActivityDetails(deviceSerialNumber: string, offset: number): Observable<any> {
    return this.http.get(`${this.domain}/devices/${deviceSerialNumber}/activity?offset=${offset}&activity_status=success`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getDeviceActivityDetailsError) => {
        this.utilService.commonErrorHandler(getDeviceActivityDetailsError);
        return throwError(() => getDeviceActivityDetailsError);
      }));
  };

  getTruSensePhORPLastCalibrationInfo(deviceSerialNumber: string): Observable<any> {
    return this.http.get(`${this.domain}/mobile/session.json?command=get_phorp_lastcalibinfo&serial=${deviceSerialNumber}&actionID=command&unit_id=1`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getTruSenseInfoError) => {
        this.utilService.commonErrorHandler(getTruSenseInfoError);
        return throwError(() => getTruSenseInfoError);
      }));
  };
  
setVSPumpName(pumpObj:any,deviceSerialNumber: string): Observable<any> {
  let pumpName = encodeURIComponent(pumpObj.pumpName);
  return this.http.get(`${this.domain}/mobile/session.json?command=set_vsp_name&serial=${deviceSerialNumber}&actionID=command&slot_id=${pumpObj.pumpId}&pump_name=${pumpName}`, {
    headers: {
      'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
    }
  }).pipe(
    catchError((setVSPumpNameError) => {
      this.utilService.commonErrorHandler(setVSPumpNameError);
    return throwError(() => setVSPumpNameError);
  }));
};

setVSPumpAppName(pumpObj:any,deviceSerialNumber: string): Observable<any> {
let app_id = parseInt(pumpObj.appId);
return this.http.get(`${this.domain}/mobile/session.json?command=set_vsp_definition&serial=${deviceSerialNumber}&actionID=command&slot_id=${pumpObj.pumpId}&app_id=${app_id}`, {
  headers: {
    'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
  }
}).pipe(
  catchError((setVSPumpAppNameError) => {
      this.utilService.commonErrorHandler(setVSPumpAppNameError);
    return throwError(() => setVSPumpAppNameError);
  }));
};

getUnassignedSerialNumbers(deviceSerialNumber: string): Observable<any> {
return this.http.get(`${this.domain}/mobile/session.json?command=get_unassigned_serials&serial=${deviceSerialNumber}&actionID=command`, {
  headers: {
    'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
  }
}).pipe(
  catchError((getUnassignedSerialNumbersError) => {
      this.utilService.commonErrorHandler(getUnassignedSerialNumbersError);
    return throwError(() => getUnassignedSerialNumbersError);
  }));
};

unassignVSPSerialNumbers(deviceSerialNumber: string,pumpObj:any): Observable<any> {
return this.http.get(`${this.domain}/mobile/session.json?command=unassign_vsp_serial&serial=${deviceSerialNumber}&actionID=command&slot_id=${pumpObj.pumpId}&vsp_serial=${pumpObj.unassignedserial}`, {
  headers: {
    'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
  }
}).pipe(
  catchError((unassignVSPError) => {
      this.utilService.commonErrorHandler(unassignVSPError);
    return throwError(() => unassignVSPError);
  }));
};

setVSPSerailNumbers(deviceSerialNumber: string,pumpObj:any): Observable<any> {
return this.http.get(`${this.domain}/mobile/session.json?command=assign_vsp_serial&serial=${deviceSerialNumber}&actionID=command&slot_id=${pumpObj.pumpId}&vsp_serial=${pumpObj.unassignedserial}`, {
  headers: {
    'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
  }
}).pipe(
  catchError((setVSPError) => {
    this.utilService.commonErrorHandler(setVSPError);
  return throwError(() => setVSPError);
}));
};

  // Set VS Pump Master Speeds
  setVSPumpMasterSpeeds(pumpObj:any, serialNumber:any, masterSpeedsObj:any) {
    return this.http.get(`${environment.API_URL}/mobile/session.json?command=set_vsp_definition&serial=${serialNumber}&actionID=command&slot_id=${pumpObj.pumpId}&min_speed=${masterSpeedsObj.vsp_min_speed}&max_speed=${masterSpeedsObj.vsp_max_speed}&prime_speed=${masterSpeedsObj.vsp_prime_speed}&prime_duration=${masterSpeedsObj.vsp_prime_duration}&freezeprotect_speed=${masterSpeedsObj.vsp_freeze_protect_speed}`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
      }));
  }

  // Set VS Pump Feature Speed Name
  setVSPumpFeatureSpeedName(serialNumber:string, speedObj:any, pumpId:number) {
    let speedName = encodeURIComponent(speedObj.speedName);
    return this.http.get(`${environment.API_URL}/mobile/session.json?command=set_speed_name&serial=${serialNumber}&actionID=command&slot_id=${pumpId}&speed_name=${speedName}&speedname_id=${speedObj.speedid}`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
      }));
  }

  // Set VS Pump Feature Speed Value
  setVSPumpFeatureSpeedValue(serialNumber:string, speedObj:any, pumpId:number) {
    return this.http.get(`${environment.API_URL}/mobile/session.json?command=set_speedname_value&serial=${serialNumber}&actionID=command&slot_id=${pumpId}&speed_value=${speedObj.speedvalue}&speedname_id=${speedObj.speedid}`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
      }));
  }

  // Set VS Pump Feature Speed Aux
  setFeatureSpeedsSpeedAux(auxIndex:number, speedId:number, pumpId:number, serialNumber:string) {
    return this.http.get(`${environment.API_URL}/mobile/session.json?command=set_aux_speed&serial=${serialNumber}&actionID=command&slot_id=${pumpId}&aux_id=${auxIndex}&speed_id=${speedId}`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
      }));
  }

  // api call to get Connection Activity Details for Automation device
  getConnectionActivityDetails(deviceSerail:string) {
    return this.http.get(`${environment.API_URL}/${deviceSerail}/connectivitylog`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
      }));
  }
  
  // http api call to get Latest OTA Job Status
  getLatestOTAJobStatus(deviceSerial:string) {
    return this.http.get(`${environment.PLATFORM_IO_URL}/${deviceSerial}/ota`,{
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
      }));
  }

  // clear failed OTA Job Updates
  clearFailedOTAJobUpdates(deviceSerial:string, deviceType:string, signature:string) {
    APP_MODEL_DATA.DEVICE_TYPES.forEach((obj:any) => {
      if(obj.Name == deviceType) {
          deviceType = obj.Id // while passing to api need tp pass actual device type id
      }
    });
    let body = {deviceType:deviceType, signature:signature};
    return this.http.post(`${environment.PLATFORM_IO_URL_V1}/${deviceSerial}/createRetryIotJob`,body,{
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
    catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
    }));
  }

  getShadowCall(deviceSerialNumber: string, signature: string): Observable<any> {

    return this.http.get(`${environment.PLATFORM_IO_URL}/${deviceSerialNumber}/shadow?signature=${signature}`, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken')
      }
    }).pipe(
      catchError((getShadowError) => {
        this.utilService.commonErrorHandler(getShadowError);
        return throwError(() => getShadowError);
      }));
  };

  // EQT-21992 | View iQpump schedules in the CX portal
  getScheduleList(serialNumber: any, secret_key: string, body: any) {

    return this.http.post(`${environment.API_URL}/devices/${serialNumber}/control.json`, body, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken'),
        'api_key': secret_key
      }
    }).pipe(
      catchError((getScheduleListError) => {
        this.utilService.commonErrorHandler(getScheduleListError);
        return throwError(() => getScheduleListError);
      }));
  }

   // Start - EQT-22552| Platform | CX Portal | Angular JS | Edit iQpump schedules in the CX portal
  // Start - EQT-22551 | Platform | CX Portal | Angular JS | New iQpump schedules in the CX portal
  editSaveDeleteScheduleList(deviceSerial: any, body: any, secret_key: any) {

    return this.http.post(`${environment.API_URL}/devices/${deviceSerial}/control.json`, body, {
      headers: {
        'Authorization': 'Bearer ' + this.utilService.getToken('idToken'),
        'api_key': secret_key
      }
    }).pipe(
      catchError((error) => {
        this.utilService.commonErrorHandler(error);
        return throwError(() => error);
      }));;
  };
}

