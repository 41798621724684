<app-header></app-header>
<router-outlet></router-outlet>
<div class="container">
  <div bsModal #commonModal="bs-modal" [config]="{backdrop: 'static'}"  class="modal fade" id="common-modal" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <p>
          <span *ngFor="let displaytext of commonModalBody">
            {{displaytext.content | translate}}
            <a *ngIf="displaytext.commonModalHrefLink!==' '" [href]="displaytext.commonModalHrefLink">{{displaytext.commonModalHrefLink}}</a>
          </span>
        </p>
        </div>
        <div class="modal-footer" *ngIf="isCommonModalButton">
          <div class="col text-center">
            <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal" (click)="commanModalClose(isSignout)">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div bsModal #successErrorModal="bs-modal" class="modal fade" id="success-error-modal" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header" [ngClass]="isError ? 'generic-errormodal' : 'generic-successmodal'">
            <h4 class="modal-title">
              <i *ngIf="!isError" class="fa fa-check-circle" style="color:green"></i>
              <i *ngIf="isError" class="fa fa-exclamation-triangle" style="color:red"></i> {{genericModalTitle}}
            </h4>
          </div>
          <div class="modal-body">
            <p class="msgInline" *ngIf="isLangKey">{{langaugeKey | translate}}</p>
            <p class="msgInline" *ngIf="!isLangKey">{{langaugeKey}}</p>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-block" data-dismiss="modal"
              (click)="successErrorModalClose(navigateTo)">OK</button>
          </div>
        </div>
      </div>
    </div>
  <!-- Show Internet Connect Alert Modal when it is Offline -->
  <div bsModal #internetConnectionAlertModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade internet-connection-alert" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
              <p><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{'lang.no_internet_connection' | translate}}</p>
          </div>
        </div>
    </div>
  </div>
</div>
    <!--Spinner for loading -->
<!--main spinner-->
<ngx-spinner
bdColor = "rgba(0, 0, 0, 0.41)"
[name]="appSpinner"
size="medium"
type="ball-spin-clockwise"
[fullScreen]="true"
>
</ngx-spinner>
