import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { APP_MODEL_DATA } from '../model-data/app-model-data';
import { NgxSpinnerService  } from "ngx-spinner";
import { ISuccessError,ICommonModalBody,ICommonModal, IMenuData, IRemoveDeviceModal, IUpdateFirmwareShowModal} from '../interface-model/app-interface-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UtilService {

  public showNavigationBar = new BehaviorSubject<boolean>(false);
  public getAuthcode = new BehaviorSubject<string>("");
  public getSignedInMessage = new BehaviorSubject<boolean>(false);
  public enableEditUser = new BehaviorSubject<boolean>(false);
  public enableCreateUser = new BehaviorSubject<boolean>(false);
  public isUpdateFirmware = new BehaviorSubject<boolean>(false);
  public showHideUpdateFirmware = new BehaviorSubject<IUpdateFirmwareShowModal>({} as any);
  public usertypes = new BehaviorSubject<Array<any>>([]);
  public successErrorModal = new BehaviorSubject<ISuccessError>({} as any);
  public commonModal = new BehaviorSubject<ICommonModal>({} as any);
  public showActiveMenu = new BehaviorSubject<IMenuData>({} as any);
  
  iaquaUrl: string = environment.IAQUA_URL;
  userTypesList: any;
  webTouchWindowsList: any = [];
  webTouchWindowsListRef: any = [];
  activeWebTouchWindowsList: any = [];
  selectedWebTouchWindow: any = [];
  checkWebTouchListInterval: any;
  webTouchWindowsListLocalStorage: any = [];

  constructor(private router: Router, public activatedRoute: ActivatedRoute, public translate: TranslateService,
    private spinner: NgxSpinnerService
 ) { }

  getLangFromParam(val: string) {
    if (APP_MODEL_DATA.SUPPORTED_LANG_TRANSLATIONS.includes(val.toLocaleLowerCase())) {
        return val.toLocaleLowerCase();
    } else {
        return 'en';
    }
  }

  getUrlParams() {
    let urlParams: any;
    this.activatedRoute.queryParams
      .subscribe(params => {
        urlParams = params;
      }
    );

    return urlParams;
  }

  checkLanguageConstraintForApp() {
    this.translate.addLangs(APP_MODEL_DATA.SUPPORTED_LANG_TRANSLATIONS);
    let currentLanguage = this.getLocalStoarge("language");
    let params = this.getUrlParams();
    if (params["lang"] === undefined) {
      let lang = currentLanguage ? currentLanguage : 'en';
      this.setLocalStorage("language", lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang)
    }
  }

  checkLanguageConstraintForSignIn() {
   let currentLanguage = this.getLocalStoarge("language")?.toLocaleLowerCase();
    let params = this.getUrlParams();
    if (params["lang"] === undefined || this.getLangFromParam(params["lang"]) !== currentLanguage || this.getLangFromParam(params["lang"]) == currentLanguage) {
      let lang = (params["lang"] === undefined)? currentLanguage || 'en' : this.getLangFromParam(params["lang"]) || currentLanguage || 'en';
      this.setLocalStorage("language", lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.router.navigate(["/sign-in"],{queryParams:{"lang": lang}});
    }
  }
  clearStorage() {
    document.cookie = 'refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'idToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.clear();
  }
  parseJwt(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  getToken(getCookieName: string) {
    let cookieName = getCookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookieData = cookieArray[i];
        while (cookieData.charAt(0) == ' ') {
            cookieData = cookieData.substring(1);
        }
        if (cookieData.indexOf(cookieName) == 0) {
            return cookieData.substring(cookieName.length, cookieData.length);
        }
    }
    return "";
}
signout()
{
  this.clearStorage();
  this.router.navigate(["/sign-in"]);
}

disableUserCheck(data: any){
  if(data.error.message=='log_disabled_user')
  {
      let successErrorData: ISuccessError ={modalTitle:'Error',isError:true,navigateTo:'/sign-in',langKey:'lang.log_disabled_user',isLangKey:true};
      this.successErrorModal.next(successErrorData);
  }
}
commonErrorHandler(getErrorHandlerData: any){
  if (getErrorHandlerData.status == 403) {
    this.clearStorage();
    this.disableUserCheck(getErrorHandlerData.error);
  } 
  else if (getErrorHandlerData.status == 401) {
    this.clearStorage();
    let successErrorData: ISuccessError = {modalTitle:'Error',isError:true,navigateTo:'/sign-in',langKey:'lang.authorization_required',isLangKey:true};
    this.successErrorModal.next(successErrorData);
  }
   else {
    let successErrorData: ISuccessError;
    if (getErrorHandlerData.error.messages) {
      if (getErrorHandlerData.error.messages[0].text.includes("_")) {
        successErrorData = { modalTitle: 'Error', isError: true, navigateTo: '', langKey: 'lang.' + getErrorHandlerData.error.messages[0].text.toLocaleLowerCase(), isLangKey: true };
        this.successErrorModal.next(successErrorData);
      }
      else if(getErrorHandlerData.error.messages[0].text){
        successErrorData = { modalTitle: 'Error', isError: true, navigateTo: '', langKey: '' + getErrorHandlerData.error.messages[0].text, isLangKey: false };
        this.successErrorModal.next(successErrorData);
      }
    }
    else if(getErrorHandlerData.error.msg){
      successErrorData = { modalTitle: 'Error', isError: true, navigateTo: '', langKey: '' + getErrorHandlerData.error.msg, isLangKey: false };
      this.successErrorModal.next(successErrorData);
    }
    else if(getErrorHandlerData?.error?.error?.message){
      successErrorData = { modalTitle: 'Error', isError: true, navigateTo: '', langKey: '' + getErrorHandlerData.error.error.message, isLangKey: false };
      this.successErrorModal.next(successErrorData);
    } 
    else if(getErrorHandlerData.message){
      successErrorData = { modalTitle: 'Error', isError: true, navigateTo: '', langKey: '' + getErrorHandlerData.message, isLangKey: false };
      this.successErrorModal.next(successErrorData);
    }
    else
    {
      successErrorData= {modalTitle:'Error',isError:true,navigateTo:'',langKey:"",isLangKey:false};
      this.successErrorModal.next(successErrorData);
    }
  }
}
invalidUserCheck(): any{
  let commonModalBodyData: ICommonModalBody[]=[];
  commonModalBodyData.push({content:'lang.invalid_user',commonModalHrefLink:''},{content:'',commonModalHrefLink:this.iaquaUrl},{content:'lang.to_login',commonModalHrefLink:''});
  let commonModalData: ICommonModal = {commonModalBody:commonModalBodyData,isCommonModalButton:true}
  return commonModalData
}
expriySessionCheck(): any{
  let commonModalBodyData: ICommonModalBody[]=[];
  commonModalBodyData.push({content:'lang.exp_session',commonModalHrefLink:''},{content:'',commonModalHrefLink:'sign-in'},{content:'lang.continue_session',commonModalHrefLink:''});
  let commonModalData: ICommonModal = {commonModalBody:commonModalBodyData,isCommonModalButton:false}
  return commonModalData
}
loginAgainCheck(): any{
  let commonModalBodyData: ICommonModalBody[]=[];
  commonModalBodyData.push({content:'lang.login_again',commonModalHrefLink:''});
  let commonModalData: ICommonModal = {commonModalBody:commonModalBodyData,isCommonModalButton:true}
  return commonModalData
}
exchangeErrorCheck(exchangeError:string): any{
  let commonModalBodyData: ICommonModalBody[]=[];
  commonModalBodyData.push({content: exchangeError,commonModalHrefLink:''});
  let commonModalData: ICommonModal = {commonModalBody:commonModalBodyData,isCommonModalButton:true}
  return commonModalData
}
deviceInfoErrorCheck(deviceInfoError:string): any{
  let commonModalBodyData: ICommonModalBody[]=[];
  commonModalBodyData.push({content: deviceInfoError,commonModalHrefLink:''});
  let commonModalData: ICommonModal = {commonModalBody:commonModalBodyData,isCommonModalButton:true,isSignout:true}
  return commonModalData
}
showSpinner(name:string): void{
  this.spinner.show(name);
}
hideSpinner(name:string): void{
  this.spinner.hide(name);
}
setLocalStorage(key:string,value:string)
{
  localStorage.setItem(key,value);
}

getLocalStoarge(key:string)
{
  return localStorage.getItem(key);
}

getUserLevel(data: any) {
  let user;
  this.userTypesList = APP_MODEL_DATA.USER_TYPES;
  APP_MODEL_DATA.USER_TYPES.forEach(element => {
    if (element.Id == data.userLevel || element.Id == data.perl_level) {
      user = element.Name;
    }
  });
  return user;
}

scrollToTarget(elementID: any) 
{
  let element = document.getElementById(elementID);
  let headerOffset = 45;
  if (element) {
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
}

setMenuActive(userMenu:boolean,deviceMenu:boolean)
{
  let tabData: IMenuData = {isUserMenu: userMenu,isDeviceMenu: deviceMenu}
  this.showActiveMenu.next(tabData);
}

replaceDeviceType(item: any) {
  APP_MODEL_DATA.DEVICE_TYPES.forEach(element => {
    if(element.Id == item.device_type) {
      item.device_type = element.Name;
  }
  });
  return item;
}

hasTranslationKey(key: string): boolean {
  const translation = this.translate.instant(key);
  return translation !== key && translation !== '';
}

  removeItemLocalStorage(key:string)
  {
    localStorage.removeItem(key);
  }

  getPropertyValWithGetHomeResponse(getHomeResponse:any, propertyName: string) {
    let propertyValue = "";
    if (getHomeResponse) {
      getHomeResponse.forEach((obj: any) => {
        if (obj[propertyName]) {
          propertyValue = obj[propertyName];
        }
      });
    }
    return propertyValue;
  }

  launchWebTouchClick(device: any, debug: string) {
    let base = environment.WEBTOUCH;
    let token = this.getToken('idToken')
    let href = base + "/?actionID=" + device.touchLink + "&idToken=" + token + debug;
    let name = device.user_id;
    let props = "height=540," + "width=860," + "toolbar=no," + "directories=no," + "status=no," + "menubar=no," + "scrollbars=no," + "resizable=no," + "modal=yes";
    
    this.webTouchWindowsListRef = JSON.parse(this.getLocalStoarge('webTouchWindowsList')!);
    if (this.webTouchWindowsList.length > 0) {
      
      this.activeWebTouchWindowsList = this.webTouchWindowsList.filter((obj: any) => !obj.webTouchWindowRef.closed);
      this.webTouchWindowsList = this.activeWebTouchWindowsList;
      this.selectedWebTouchWindow = this.webTouchWindowsList.filter((obj: any) => obj.webTouchWindowName === name);
      if (this.selectedWebTouchWindow.length > 0) {
        if (this.selectedWebTouchWindow[0].debug !== debug) {
          // remove the old window reference and add the new window reference
          let objIndex = this.webTouchWindowsList.findIndex((obj: any) => obj.webTouchWindowName === name);
          this.webTouchWindowsList.splice(objIndex, 1);
          this.webTouchWindowsList.push({ "webTouchWindowRef": window.open(href, name, props), "webTouchWindowName": name, "deviceInfo": device, "debug": debug });
          // update webTouchWindowsList to local storage 
          this.webTouchWindowsListRef = this.webTouchWindowsList.map((obj: any) => {
            return { "webTouchWindowName": obj.webTouchWindowName, "deviceInfo": obj.deviceInfo, "debug": obj.debug };
          });
          this.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListRef));
        }
        else {
          window.open(href, name, props);
        }
      }
      else {
        this.webTouchWindowsList.push({ "webTouchWindowRef": window.open(href, name, props), "webTouchWindowName": name, "deviceInfo": device, "debug": debug });
        // update webTouchWindowsList to local storage
        this.webTouchWindowsListRef = this.webTouchWindowsList.map((obj: any) => {
          return { "webTouchWindowName": obj.webTouchWindowName, "deviceInfo": obj.deviceInfo, "debug": obj.debug };
        });
        this.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListRef));
      }
    }
    else {
      this.webTouchWindowsList.push({ "webTouchWindowRef": window.open(href, name, props), "webTouchWindowName": name, "deviceInfo": device, "debug": debug });
      // update webTouchWindowsList to local storage
      this.webTouchWindowsListRef = this.webTouchWindowsList.map((obj: any) => {
        return { "webTouchWindowName": obj.webTouchWindowName, "deviceInfo": obj.deviceInfo, "debug": obj.debug };
      });
      this.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListRef));
    }
    this.trackWebTouchLists();
  }

  trackWebTouchLists() {
    if (!this.checkWebTouchListInterval) {
      this.checkWebTouchListInterval = setInterval(() => {
        if (this.webTouchWindowsList.length > 0) {
          for (let i = 0; i < this.webTouchWindowsList.length; i++) {
            if (this.webTouchWindowsList[i].webTouchWindowRef && this.webTouchWindowsList[i].webTouchWindowRef.closed) {
              this.webTouchWindowsListLocalStorage = JSON.parse(this.getLocalStoarge('webTouchWindowsList')!);
              if (this.webTouchWindowsListLocalStorage.length > 0) {
                let removeObjIndex = this.webTouchWindowsListLocalStorage.findIndex((obj: any) => obj.webTouchWindowName === this.webTouchWindowsList[i].webTouchWindowName);
                if (removeObjIndex !== -1) {
                  this.webTouchWindowsListLocalStorage.splice(removeObjIndex, 1);
                  this.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListLocalStorage));
                }
              }
              else if (this.checkWebTouchListInterval) {
                clearInterval(this.checkWebTouchListInterval);
                this.checkWebTouchListInterval = undefined;
              }
            }
          }
        }
      }, APP_MODEL_DATA.TRACK_WEBTOUCH_TIMER);
    }
  }

  launchWebTouchOnExchangeToken() {
    if (this.webTouchWindowsList && this.webTouchWindowsList.length > 0) {
      for (let i = 0; i < this.webTouchWindowsList.length; i++) {
        let webTouchObj = this.webTouchWindowsList[i];
        if (!webTouchObj.webTouchWindowRef.closed) {
          this.appendNewExchangeToken(webTouchObj);
        }
        else {
          this.webTouchWindowsListRef = JSON.parse(this.getLocalStoarge('webTouchWindowsList')!);
          if (this.webTouchWindowsListRef && this.webTouchWindowsListRef.length > 0) {
            let objIndex = this.webTouchWindowsListRef.findIndex((obj: any) => obj.webTouchWindowName === webTouchObj.webTouchWindowName);
            this.webTouchWindowsListRef.splice(objIndex, 1);
            this.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListRef));
          }
          this.webTouchWindowsList.splice(i, 1);
        }
      }
    }
    else {
      this.webTouchWindowsListRef = JSON.parse(this.getLocalStoarge('webTouchWindowsList')!);
      if (this.webTouchWindowsListRef && this.webTouchWindowsListRef.length > 0) {
        for (let i = 0; i < this.webTouchWindowsListRef.length; i++) {
          let webTouchObj = this.webTouchWindowsListRef[i];
          this.webTouchWindowsList.push({ "webTouchWindowRef": window.open('', webTouchObj.webTouchWindowName), "webTouchWindowName": webTouchObj.webTouchWindowName, "deviceInfo": webTouchObj.deviceInfo, "debug": webTouchObj.debug });
          this.appendNewExchangeToken(this.webTouchWindowsList[i]);
        }
      }
    }
  }

  appendNewExchangeToken(webTouchObj: any) {
    let token = this.getToken('idToken');
    if (webTouchObj.webTouchWindowRef && !webTouchObj.webTouchWindowRef.closed) {
      webTouchObj.webTouchWindowRef.postMessage({ "newIdToken": token }, "*");
    }
    this.trackWebTouchLists();
  }

  calculatePercentage (data:any) {
    var dataSplit = data.statusResponse.split(' ');
    var actualtransferedCount = dataSplit[1];
    var TotalFileCount = dataSplit[3];
    var percentage = (parseInt(actualtransferedCount) * 100) / parseInt(TotalFileCount);
    return percentage;
  }

  calculatePercentagePump(data:any) {
    var dataSplit = data.response.split('/');
    var actualtransferedCount = dataSplit[0];
    var TotalFileCount = dataSplit[1];
    var percentage = (parseInt(actualtransferedCount) * 100) / parseInt(TotalFileCount);
    return percentage;
}

  // get dynamic translation text
  getDynamicTranslationText(langKey: string, params?: any) {
    let dynamicText = ''
    this.translate.get(langKey, params).subscribe((responseText: string) => {
      dynamicText = responseText;
    });
    return dynamicText;
  }

  // EQT-21992 | View iQpump schedules in the CX portal
  getTime(time: any) {
    let hours = time.substring(0, 2);
    let minutes = time.substring(2, 4);
    let timeValue;

    if (hours > 0 && hours <= APP_MODEL_DATA.SCHEDULES.HOURS_FORMAT_12) {
        timeValue = "" + hours;
    } else if (hours > APP_MODEL_DATA.SCHEDULES.HOURS_FORMAT_12) {
        timeValue = "" + (hours - APP_MODEL_DATA.SCHEDULES.HOURS_FORMAT_12);
    } else if (hours == 0) {
        timeValue = "12";
    }
    timeValue += (minutes < 10) ? ":" + minutes : ":" + minutes;
    timeValue += (hours >= 12) ? "pm" : "am";
    return timeValue;
  }

  // Start | EQT-22432 | Platform | CX Portal | AngularJS | New iQpump schedules in the CX portal
  // Start | EQT-22552 | Platform | CX Portal | AngularJS | New iQpump schedules in the CX portal
  updateRPM() {
    let speed = [];
    for (let i = APP_MODEL_DATA.SCHEDULES.MIN_SUPPORTED_RPM; i <= APP_MODEL_DATA.SCHEDULES.MAX_SUPPORTED_RPM; i += APP_MODEL_DATA.SCHEDULES.DEFAULT_RPM_INCREMENT) {
      speed.push(i);
    }
    return speed;
  }
}
